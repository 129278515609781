import { React } from "react";
import "./style/income-tax-description.css";
import { ReactComponent as MascotGreen } from "../../../assets/svg/income-tax/mascot-green.svg";
import { ReactComponent as InfoPointer } from "../../../assets/svg/income-tax/info-pointer.svg";
import { ReactComponent as InfoIcon } from "../../../assets/svg/income-tax/info-icon.svg";
import { ReactComponent as InfoBody } from "../../../assets/svg/income-tax/transparent-info-body.svg";
import { ReactComponent as InfoDot } from "../../../assets/svg/income-tax/transparent-info-dot.svg";

const FinanceDescription = ({ title, content }) => {
  console.log("desc title", title);
  console.log("desc content", content);
  return (
    <div className="income-tax-description-container">
      <div className="income-tax-description-mascot">
        <MascotGreen />
      </div>
      <div className="income-tax-description-content">
        <h2>{title}</h2>
        <p>{content}</p>
        <button>Meer info</button>
      </div>
      <div className="income-tax-description-icon">
        <InfoDot className="info-dot" />
        <InfoBody className="info-body" />
      </div>
    </div>
  );
};

export default FinanceDescription;
