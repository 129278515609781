import React from "react";
import { AuthProvider } from "../../context/authContext";
import { BrowserRouter as Router } from "react-router-dom";
import App from "../../App";

const AppWrapper = () => {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
};

export default AppWrapper;
