import React from "react";
import { useAuth } from "../../context/authContext";

const LogoutButton = () => {
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      window.location.href = "/login";
    } catch (error) {
      console.error("Fout bij het uitloggen:", error);
    }
  };

  return (
    <button onClick={handleLogout} className="auth-button red-bg">
      Uitloggen
    </button>
  );
};

export default LogoutButton;
