import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceEnergyStep({ handleChange, companyResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Loopje toevoegen voor meerdere auto's
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 4</h2>
      <div>
        <h1>Hoeveel betaalt u aan gas?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.gasCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.gasCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan electriciteit?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.electricityCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.electricityCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan water?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.waterCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.waterCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan onroerende zaakbelasting?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.realEstateTax}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.realEstateTax",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan reinigingsheffing?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.cleaningLevy}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.cleaningLevy",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan rioolheffing?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.sewageCharges}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.sewageCharges",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan waterschapslasten?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.waterBoardCharges}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.waterBoardCharges",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overige lokale lasten?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.energyCosts.localCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "energyCosts.localCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceEnergyStep;
