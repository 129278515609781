import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function DividendStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 11</h2>
      <h1>Heeft u dividend uitgekeerd uit uw BV?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.dividend === true ? "btn-yes selected-btn" : "btn-yes"
          }
          onClick={() => handleFieldUpdate("dividend", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.dividend === false ? "btn-no selected-btn" : "btn-no"
          }
          onClick={() => handleFieldUpdate("dividend", false)}
        >
          Nee
        </button>
      </div>
      {companyResult.dividend && (
        <div>
          <h1>Hoeveel dividend heeft u het afgelopen jaar uitbetaald?</h1>
          <div className="input-container">
            <label>Bedrag</label>
            <input
              type="number"
              value={companyResult.dividendPaid}
              onChange={(e) =>
                handleFieldUpdate("dividendPaid", parseFloat(e.target.value))
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default DividendStep;
