// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.income-tax-description-container {
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  position: relative;
}

.income-tax-description-container h1 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.income-tax-description-container p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}

.income-tax-description-container button {
  background: var(--Blue, #3a489d);
}

.income-tax-description-icon {
  position: absolute;
  opacity: 0.04;
  display: flex;
  flex-direction: column;
  right: 0;
}

.info-dot {
  position: absolute;
  top: 21px;
  right: -10px;
}

.info-body {
  position: absolute;
  right: -10px;
  top: 133px;
}
`, "",{"version":3,"sources":["webpack://./src/components/finance-wizard/finance-description/style/income-tax-description.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ","sourcesContent":[".income-tax-description-container {\n  display: flex;\n  flex-direction: column;\n  padding: 40px 24px;\n  position: relative;\n}\n\n.income-tax-description-container h1 {\n  font-family: Montserrat;\n  font-size: 16px;\n  font-weight: 700;\n  line-height: 20px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.income-tax-description-container p {\n  font-family: Montserrat;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n  text-align: left;\n}\n\n.income-tax-description-container button {\n  background: var(--Blue, #3a489d);\n}\n\n.income-tax-description-icon {\n  position: absolute;\n  opacity: 0.04;\n  display: flex;\n  flex-direction: column;\n  right: 0;\n}\n\n.info-dot {\n  position: absolute;\n  top: 21px;\n  right: -10px;\n}\n\n.info-body {\n  position: absolute;\n  right: -10px;\n  top: 133px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
