import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function ConfigOverview() {
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/configuration", {
          withCredentials: true,
        });
        const data = await response.data.data;
        setConfigs(data.configs);
      } catch (error) {
        if (error.response) {
          console.error("Server Error:", error.response.data);
          console.error("Status Code:", error.response.status);
          console.error("Headers:", error.response.headers);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Request setup error:", error.message);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="column-full-width center">
      <h1>Inkomstenbelasting configuraties</h1>
      <table>
        <thead>
          <tr>
            <th>Jaar</th>
            <th>
              <button>
                <Link to={`/config/${currentYear}`}>
                  Nieuwe Configuratie maken
                </Link>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {configs &&
            configs.map((config) => (
              <tr key={config.year}>
                <td>
                  <Link to={`/config/${config.year}`}>
                    Configuratie Inkomstenbelasting {config.year}
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default ConfigOverview;
