// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.income-tax-intro-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.income-tax-intro-container h1 {
  flex-basis: 100%;
}

.income-tax-intro-content {
  display: flex;
  flex-basis: 100%;
  width: 100%;
  color: var(--Dark, #101a42);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.income-tax-intro-image {
  width: 42.959px;
  height: 196px;
  flex-shrink: 0;
  position: absolute;
  right: 40px;
  top: 40px;
}

.income-tax-intro-text {
  width: 193px;
}
`, "",{"version":3,"sources":["webpack://./src/components/net-income-wizard/intro-step/style/intro-step.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,WAAW;EACX,2BAA2B;EAC3B,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".income-tax-intro-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}\n\n.income-tax-intro-container h1 {\n  flex-basis: 100%;\n}\n\n.income-tax-intro-content {\n  display: flex;\n  flex-basis: 100%;\n  width: 100%;\n  color: var(--Dark, #101a42);\n  font-family: Montserrat;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n\n.income-tax-intro-image {\n  width: 42.959px;\n  height: 196px;\n  flex-shrink: 0;\n  position: absolute;\n  right: 40px;\n  top: 40px;\n}\n\n.income-tax-intro-text {\n  width: 193px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
