import { React } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoutButton from "../logout-button/logout-button.component";
import "./style/top-navigation.css";
import { ReactComponent as Logo } from "../../assets/svg/top-logo.svg";
import { useAuth } from "../../context/authContext";

const TopNavigation = ({ user }) => {
  const navigate = useNavigate();

  const navigateTo = (path) => () => {
    navigate(path);
  };

  const { currentUser } = useAuth();
  console.log("CURRENTUSER", currentUser);

  return (
    <nav className="top-navigation-container">
      <div className="top-logo">
        <Logo />
      </div>
      <div className="top-navigation-items">
        <Link to="/calculation">Berekeningen</Link>
        <Link to="/configurations">Configuraties</Link>
        <Link to="/finance/">Kasboek</Link>
        <Link to="/about">Over ons</Link>
      </div>
      {user === null && (
        <div className="top-navigation-auth">
          <button
            onClick={navigateTo("/register")}
            className="auth-button register"
          >
            Registreren
          </button>
          <button onClick={navigateTo("/login")} className="auth-button login">
            Login
          </button>
        </div>
      )}
      {user !== null && (
        <div className="top-navigation-auth">
          <button onClick={navigateTo("/calculation")} className="auth-button">
            Dashboard
          </button>
          <LogoutButton />
        </div>
      )}
    </nav>
  );
};

export default TopNavigation;
