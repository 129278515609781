import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function LegalFormStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 3</h2>
      <h1>Heeft u of uw partner een eenmanszaak of VOF?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.isEntrepeneur === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleFieldUpdate("isEntrepeneur", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.isEntrepeneur === false
              ? "btn-no selected-btn"
              : "btn-no"
          }
          onClick={() => handleFieldUpdate("isEntrepeneur", false)}
        >
          Nee
        </button>
        {companyResult.isEntrepeneur && (
          <div>
            <div>
              <h1>Bent u de eigenaar van een eenmanszaak of VOF?</h1>
              <button
                className={
                  companyResult.mainUserIsEntrepeneur === true
                    ? "btn-yes selected-btn"
                    : "btn-yes"
                }
                onClick={() => handleFieldUpdate("mainUserIsEntrepeneur", true)}
              >
                Ja
              </button>
              <button
                className={
                  companyResult.mainUserIsEntrepeneur === false
                    ? "btn-no selected-btn"
                    : "btn-no"
                }
                onClick={() =>
                  handleFieldUpdate("mainUserIsEntrepeneur", false)
                }
              >
                Nee
              </button>
            </div>
          </div>
        )}
        {(!companyResult.mainUserIsEntrepeneur || !companyResult.isEntrepeneur) && (
          <div>
            <h1>
              Is uw fiscaal partner de eigenaar van een eenmanszaak of VOF?
            </h1>
            <button
              className={
                companyResult.fiscalPartnerIsEntrepeneur === true
                  ? "btn-yes selected-btn"
                  : "btn-yes"
              }
              onClick={() => {
                  handleFieldUpdate("fiscalPartnerIsEntrepeneur", true)
                  handleFieldUpdate("isEntrepeneur", true)
                }
              }
            >
              Ja
            </button>
            <button
              className={
                companyResult.fiscalPartnerIsEntrepeneur === false
                  ? "btn-no selected-btn"
                  : "btn-no"
              }
              onClick={() =>
                handleFieldUpdate("fiscalPartnerIsEntrepeneur", false)
              }
            >
              Nee
            </button>
          </div>
        )}
        {companyResult.isEntrepeneur && (
            <div>
                <h1>
                    In welk jaar is dit bedrijf opgericht?
                </h1>
                <input
                    type="number"
                    value={companyResult.companyYear}
                    onChange={(e) =>
                        handleFieldUpdate(
                            "companyYear",
                            parseFloat(e.target.value)
                        )
                    }
                />
            </div>
        )}
        {(companyResult.companyYear >= (new Date().getFullYear() - 5) && companyResult.isEntrepeneur) && (
            <div>
                <h1>
                    Is er vaker dan 3 keer de startersaftrek toegepast?
                </h1>
                <button
                    className={
                        companyResult.starterDeductionEligible === false
                            ? "btn-yes selected-btn"
                            : "btn-yes"
                    }
                    onClick={() =>
                        handleFieldUpdate("starterDeductionEligible", false)
                    }
                >
                    Ja
                </button>
                <button
                    className={
                        companyResult.starterDeductionEligible === true
                            ? "btn-no selected-btn"
                            : "btn-no"
                    }
                    onClick={() =>
                        handleFieldUpdate("starterDeductionEligible", true)
                    }
                >
                    Nee
                </button>
            </div>
        )}
      </div>
    </div>
  );
}

export default LegalFormStep;
