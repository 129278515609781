import { useState } from "react";

export function useForm(initialState) {
  const [formState, setFormState] = useState(initialState);

  const handleChange = (field, value) => {
    console.log(`Changing ${field} to ${value}`);
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleDifferentiatedRevenueChange = (month, value) => {
    setFormState((prevState) => ({
      ...prevState,
      differentiatedRevenue: {
        ...prevState.differentiatedRevenue,
        [month]: value,
      },
    }));
  };

  const handleCheckboxChange = (field, value) => {
    setFormState({ ...formState, [field]: !formState[field] });
  };

  return [
    formState,
    handleChange,
    handleDifferentiatedRevenueChange,
    handleCheckboxChange,
  ];
}
