import React from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import taxCalculations from "../../../utils/taxCalculations";

import "../style/net-income-wizard.style.css";
import "./style/income-result.style.css";

function IncomeResult({ companyResult, incomeTaxConfig }) {
  const { companyId, year } = useParams();
  console.log("CR", companyResult);
  const deductionData = {
    fiscalPartner: companyResult.fiscalPartner,
    wozValue: companyResult.wozValue,
    notionalRentalValueMin: incomeTaxConfig.notionalRentalValueMin,
    notionalRentalValueMax: incomeTaxConfig.notionalRentalValueMax,
    mortgageInterestDeduction: companyResult.mortgageInterestDeduction,
    paidMortgageInterest: companyResult.deductions.paidMortgageInterest,
    paidMortgageCosts: companyResult.deductions.paidMortgageCosts,
    mortgageCosts: companyResult.deductions.mortgageCosts,
    medicalExpenses: companyResult.deductions.medicalExpenses,
    paidMedicalExpenses: companyResult.deductions.paidMedicalExpenses,
    deductableMedicalExpensesBrackets:
      incomeTaxConfig.deductableMedicalExpensesBrackets,
    paidPartnerAlimony: companyResult.deductions.paidPartnerAlimony,
    paidAnnuity: companyResult.deductions.paidAnnuity,
    paidTravelExpenses: companyResult.deductions.paidTravelExpenses,
    starterDeduction: incomeTaxConfig.starterDeduction,
    selfEmploymentDeduction: incomeTaxConfig.selfEmploymentDeduction,
    smeProfitExemption: incomeTaxConfig.smeProfitExemption,
    starterDeductionYears: incomeTaxConfig.starterDeductionYears,
    hoursCriterion: incomeTaxConfig.hoursCriterion,
  };

  const incomeData = {
    companyYear: companyResult.companyYear,
    grossExpectedRevenue: companyResult.grossExpectedRevenue,
    grossIncomeMiscellaneous: companyResult.grossIncomeMiscellaneous,
    grossIncomeEmployment: companyResult.grossIncomeEmployment,
    grossExpectedCosts: companyResult.grossExpectedCosts,
    grossExpectedInvestments: companyResult.grossExpectedInvestments,
    dividendPaid: companyResult.dividendPaid,
    hoursWorkingAsEntrepeneur: companyResult.hoursWorkingAsEntrepeneur,
    starterDeductionEligible: companyResult.starterDeductionEligible,
  };

  const capitalData = {
    capitalOverTaxFreeLimit: companyResult.capitalOverTaxFreeLimit,
    capitalSavings: companyResult.capitalSavings,
    capitalInvestments: companyResult.capitalInvestments,
    capitalDebts: companyResult.capitalDebts,
  };

  const capitalTaxData = {
    taxFreeCapitalLimit: incomeTaxConfig.taxFreeCapitalLimit,
    limitDeductableDebt: incomeTaxConfig.limitDeductableDebt,
    fixedReturnOnSavings: incomeTaxConfig.fixedReturnOnSavings,
    fixedReturnOnInvestments: incomeTaxConfig.fixedReturnOnInvestments,
    fixedReturnOnDebts: incomeTaxConfig.fixedReturnOnDebts,
    capitalTaxPercentage: incomeTaxConfig.capitalTaxPercentage,
  };
  //NOTE Box 1 results
  const grossCompanyProfit =
    incomeData.grossExpectedRevenue +
    incomeData.grossIncomeMiscellaneous -
    incomeData.grossExpectedCosts;

  const ownerOccupiedHousingDeduction =
    taxCalculations.calculateDeductionOwnerOccupiedHousing(deductionData);

  const totalIncome = taxCalculations.calculateTotalIncome(
    incomeData,
    deductionData
  );
  const medicalExpensesDeduction =
    taxCalculations.calculateDeductionMedicalExpenses(
      deductionData.medicalExpenses,
      deductionData.paidMedicalExpenses,
      deductionData.deductableMedicalExpensesBrackets
    );

  const taxableFiscalProfit =
    taxCalculations.calculateOrganisationFiscalTaxableProfit(
      incomeData,
      deductionData
    );

  const taxableIncomeBox1 = taxCalculations.calculateTaxableIncomeBox1(
    totalIncome,
    deductionData
  );

  console.log("taxableIncome", taxableFiscalProfit);

  const incomeTax = taxCalculations.calculateIncomeTax(
    taxableIncomeBox1,
    incomeTaxConfig.incomeTaxBrackets
  );
  let incomeRelatedCombinationTaxCredit = 0
  if (companyResult.children) {
    incomeRelatedCombinationTaxCredit =
        taxCalculations.calculateIncomeRelatedCombinationTaxCredit(
            grossCompanyProfit,
            incomeTaxConfig.incomeRelatedCombinationTaxBrackets,
            deductionData.fiscalPartner
        );
  }
  const labourDiscount = taxCalculations.calculatelabourDiscount(
    grossCompanyProfit,
    incomeTaxConfig.labourDiscountBrackets
  );
  const investmentDeduction = taxCalculations.calculateInvestmentDeduction(
    incomeData.grossExpectedInvestments,
    incomeTaxConfig.investmentDeductionBrackets
  );
  const generalTaxCredit = taxCalculations.calculateGeneralTaxCredit(
      taxableIncomeBox1 + incomeData.grossIncomeEmployment,
    incomeTaxConfig.generalTaxCreditBrackets
  );
  console.log("HEFFINGSKORTING", generalTaxCredit)
  const healthcareLaw = taxCalculations.calculateHealthcareLaw(
    taxableIncomeBox1,
    incomeTaxConfig.healthcareLawPercentage
  );

  const incomeTaxAfterDiscounts =
    incomeTax -
    incomeRelatedCombinationTaxCredit -
    generalTaxCredit -
    ownerOccupiedHousingDeduction -
    medicalExpensesDeduction -
    labourDiscount;

  console.log("incomeRelatedCombinationTaxCredit", incomeRelatedCombinationTaxCredit)

  //NOTE Box 2 results
  const dividendTax = taxCalculations.calculateDividendTax(
    incomeData.dividendPaid,
    incomeTaxConfig.dividendTaxPercentage
  );
  //NOTE Box 3 results
  const box3Tax = taxCalculations.calculateBox3Tax(capitalData, capitalTaxData);
  console.log("grossExpectedInvestments", incomeData.grossExpectedInvestments);
  console.log("incomeTaxAfterDiscounts", incomeTaxAfterDiscounts);
  const spendableAfterTax = taxCalculations.calculateSpendableIncomeAfterTax(
    grossCompanyProfit,
    incomeData.grossExpectedInvestments,
    incomeTaxAfterDiscounts,
    dividendTax,
    box3Tax,
    healthcareLaw
  );

  console.log("spendableAfterTax", spendableAfterTax);

  const monthlySpendableAfterTax =
    taxCalculations.calculateSpendableMonthlyIncomeAfterTax(spendableAfterTax);
  const monthlyIncomeTax = Math.abs(incomeTaxAfterDiscounts / 12);
  const monthlyHealthCareLaw = Math.abs(healthcareLaw / 12);
  const totalSum =
    Math.abs(monthlyIncomeTax) +
    Math.abs(monthlyHealthCareLaw) +
    Math.abs(monthlySpendableAfterTax);

  const incomeTaxProportion = (Math.abs(monthlyIncomeTax) / totalSum) * 100;
  const healthcareLawProportion =
    (Math.abs(monthlyHealthCareLaw) / totalSum) * 100;

  const spendableIncomeProportion =
    (Math.abs(monthlySpendableAfterTax) / totalSum) * 100;

  useEffect(() => {
    if (spendableAfterTax !== 0) {
      const updateIncomeAfterTax = async () => {
        try {
          const url = `${process.env.REACT_APP_API_BASE_URL}/companyresults/${companyId}/${year}`;
          const response = await axios.put(
            url,
            {
              spendableAfterTax,
            },
            {
              withCredentials: true,
            }
          );
          console.log("Income After Tax updated successfully:", response.data);
        } catch (error) {
          if (error.response) {
            console.error("Server Error:", error.response.data);
            console.error("Status Code:", error.response.status);
            console.error("Headers:", error.response.headers);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Request setup error:", error.message);
          }
        }
      };

      updateIncomeAfterTax();
    }
  }, [spendableAfterTax, companyId]);

  return (
    <div className="income-tax-content-container">
      <h1 className="center">Alles is correct ingevuld!</h1>
      <div className="income-tax-overview-container">
        <div class="overview-text">
          <p>
            U heeft de complete wizard doorlopen en hier onder zie je wat er
            be-rekend is!
          </p>
          <p>
            Mocht u meer detail informatie willen het desbetreffende onderwerp
            uitklappen.
          </p>
          <p>
            En nog een verwijzing naar het dashboard waar ze hun financien bij
            kunnen houden.
          </p>
        </div>
        <div class="overview-results">
          <div class="result-item">
            <span class="indicator green-indicator"></span>
            <span class="amount">
              {parseFloat(monthlySpendableAfterTax).toFixed(2)}
            </span>
            <span class="description">Besteedbaar inkomen</span>
          </div>
          <div class="result-item">
            <span class="indicator red-indicator"></span>
            <span class="amount">
              {parseFloat(incomeTaxAfterDiscounts / 12).toFixed(2)}
            </span>
            <span class="description">Inkomsten belasting</span>
          </div>
          <div class="result-item">
            <span class="indicator yellow-indicator"></span>
            <span class="amount">
              {parseFloat(healthcareLaw / 12).toFixed(2)}
            </span>
            <span class="description">Bijdrage zorgverzekeringswet</span>
          </div>
        </div>
      </div>
      <div className="income-distribution-bar">
        <div className="bar">
          <div
            className="segment green-segment"
            style={{ width: `${spendableIncomeProportion}%` }}
          >
            <span className="label">
              {spendableIncomeProportion.toFixed(1)}%
            </span>
          </div>
          <div
            className="segment red-segment"
            style={{ width: `${incomeTaxProportion}%` }}
          >
            <span className="label">{incomeTaxProportion.toFixed(1)}%</span>
          </div>
          <div
            className="segment yellow-segment"
            style={{ width: `${healthcareLawProportion}%` }}
          >
            <span className="label">{healthcareLawProportion.toFixed(1)}%</span>
          </div>
        </div>
      </div>
      <div>
        <h1>RESULTAAT - CONTROLE LIJST - WORDT VERWIJDERD NA TEST</h1>
        <ul>
          <li>
            Opbrengsten:
            {parseFloat(incomeData.grossExpectedRevenue).toFixed(2)}
          </li>
          <li>
            Overige bedrijfskosten:
            {parseFloat(incomeData.grossExpectedCosts).toFixed(2)}
          </li>
          <li>
            Saldo fiscale winstberekening
            {parseFloat(grossCompanyProfit).toFixed(2)}
          </li>
        </ul>
        <h1>BELASTBAAR INKOMEN - CONTROLE LIJST</h1>
        <ul>
          <li>
            Belastbaar inkomen box 1:
            {parseFloat(taxableIncomeBox1).toFixed(2)}
          </li>
          <li>
            Gecombineerde inkomensheffing:
            {parseFloat(incomeTax).toFixed(2)}
          </li>
          <li>
            Inkomensgerelateerde combinatiekorting:
            {parseFloat(incomeRelatedCombinationTaxCredit).toFixed(2)}
          </li>
          <li>Arbeidskorting: {parseFloat(labourDiscount).toFixed(2)}</li>
          <li>Heffingskorting: {parseFloat(generalTaxCredit).toFixed(2)}</li>
          <li>
            Investeringsaftrek: {parseFloat(investmentDeduction).toFixed(2)}
          </li>
          <li>Verschuldigde belasting en premie: {parseFloat(incomeTaxAfterDiscounts).toFixed(2)}</li>
          <li>Bijdrage ZVW: {parseFloat(healthcareLaw).toFixed(2)}</li>
        </ul>
        <h2>BOX 2 RESULTATEN</h2>
        <ul>
          <li>
            <b>Dividend uitbetaald</b>{" "}
            {parseFloat(companyResult.dividendPaid).toFixed(2)}
          </li>
          <li>
            <b>Dividendbelasting</b> {parseFloat(dividendTax).toFixed(2)}
          </li>
        </ul>
        <h2>BOX 3 RESULTATEN</h2>
        <ul>
          <li>
            <b>Rendement</b>{" "}
            {parseFloat(
              taxCalculations.calculateCapitalReturnPercentage(
                capitalData,
                capitalTaxData
              ) * 100
            ).toFixed(2)}
            %
          </li>
          <li>
            <b>Box 3 inkomen</b>{" "}
            {parseFloat(
              taxCalculations.calculateBox3Income(capitalData, capitalTaxData)
            ).toFixed(2)}
          </li>
          <li>
            <b>Box 3 belasting</b> {parseFloat(box3Tax).toFixed(2)}
          </li>
        </ul>
        <p>
          Inkomstenbelasting na aftrekposten:{" "}
          {parseFloat(incomeTaxAfterDiscounts).toFixed(2)}
        </p>
        <p>
          <b>Vrij besteedbaar inkomen: </b>{" "}
          {parseFloat(spendableAfterTax).toFixed(2)}
        </p>
        <p>
          <b>Vrij besteedbaar inkomen per maand: </b>{" "}
          {parseFloat(monthlySpendableAfterTax).toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export default IncomeResult;
