import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceUnreimbursedMedicalCostsStep({
  handleChange,
  companyResult,
  financeResult,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 12</h2>
      <div>
        <h1>Hoeveel betaalt u aan eigen risico?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.unreimbursedMedicalCosts.ownRiskCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "unreimbursedMedicalCosts.ownRiskCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan zelfzorgmedicijnen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={
              financeResult.unreimbursedMedicalCosts.overTheCounterMedicineCosts
            }
            onChange={(e) =>
              handleFieldUpdate(
                "unreimbursedMedicalCosts.overTheCounterMedicineCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan eigen bijdrage?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.unreimbursedMedicalCosts.contributionCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "unreimbursedMedicalCosts.contributionCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceUnreimbursedMedicalCostsStep;
