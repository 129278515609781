const calculateTotalSteps = (categories) => {
    let totalSteps = 0;

    for (const category in categories) {
        if (categories.hasOwnProperty(category)) {
            const steps = categories[category].steps;
            totalSteps += Object.keys(steps).length;
        }
    }

    return totalSteps;
};

export const stepCalculations = {
    calculateTotalSteps,
};

export default stepCalculations;