import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceInsuranceStep({ handleChange, companyResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 5</h2>
      <div>
        <h1>Hoeveel betaalt u aan zorgverzekering?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.insuranceCosts.healthInsuranceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "insuranceCosts.healthInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan aansprakelijkheidsverzekering?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.insuranceCosts.liabilityInsuranceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "insuranceCosts.liabilityInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan inboedel- en opstalverzekering?</h1>
        <div className="input-container">
          <input
            type="number"
            value={
              financeResult.insuranceCosts.contentAndBuildingInsuranceCosts
            }
            onChange={(e) =>
              handleFieldUpdate(
                "insuranceCosts.contentAndBuildingInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>
          Hoeveel betaalt u aan onroerende uitvaart-, en/of levensverzekering?
        </h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.insuranceCosts.lifeInsuranceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "insuranceCosts.lifeInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overige verzekeringen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.insuranceCosts.miscInsuranceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "insuranceCosts.miscInsuranceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceInsuranceStep;
