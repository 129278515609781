const calculateSmeProfitExemption = (profit, smeProfitExemptionPercentage) => {
  if (smeProfitExemptionPercentage) {
    const percentageProfit = profit / 100;
    const smeProfitExemption = percentageProfit * smeProfitExemptionPercentage;
    return Math.round(smeProfitExemption);
  }
  return 0;
};

const calculateHousingIncome = (deductionData) => {
  if (!deductionData.wozValue) return 0;
  return deductionData.wozValue <= 1200000
    ? deductionData.wozValue * deductionData.notionalRentalValueMin
    : deductionData.wozValue * deductionData.notionalRentalValueMax;
};

const checkSelfEmploymentDeduction = (
  hoursCriterion,
  hoursWorkingAsEntrepeneur
) => {
  if (!hoursCriterion || !hoursWorkingAsEntrepeneur) {
    return false;
  }
  return hoursCriterion <= hoursWorkingAsEntrepeneur;
};

const calculateDeductionOwnerOccupiedHousing = (deductionData) => {
  let deductableOwnerOccupiedHousing = 0;
  if (deductionData.mortgageInterestDeduction === true) {
    deductableOwnerOccupiedHousing += deductionData.paidMortgageInterest;
  }

  if (deductionData.mortgageCosts === true) {
    deductableOwnerOccupiedHousing += deductionData.paidMortgageCosts;
  }
  if (
    deductionData.mortgageCosts === true ||
    deductionData.mortgageInterestDeduction === true
  ) {
    deductableOwnerOccupiedHousing -= calculateHousingIncome(deductionData);
  }
  return deductableOwnerOccupiedHousing;
};

const calculateOrganisationFiscalTaxableProfit = (
  incomeData,
  deductionData
) => {
  const grossProfit =
    incomeData.grossExpectedRevenue + incomeData.grossIncomeMiscellaneous;
  const totalCosts = incomeData.grossExpectedCosts;
  let taxableProfit = grossProfit - totalCosts;

  if (incomeData.starterDeductionEligible) {
    taxableProfit -= deductionData.starterDeduction;
  }

  const isEligibleForSelfEmploymentDeduction = checkSelfEmploymentDeduction(
    deductionData.hoursCriterion,
    incomeData.hoursWorkingAsEntrepeneur
  );

  if (isEligibleForSelfEmploymentDeduction) {
    taxableProfit -= deductionData.selfEmploymentDeduction;
  }

  const smeProfitExemptionAmount = calculateSmeProfitExemption(
    taxableProfit,
    deductionData.smeProfitExemption
  );
  taxableProfit -= smeProfitExemptionAmount;
  return taxableProfit;
};

const calculateTotalIncome = (incomeData, deductionData) => {

  let fiscalTaxableProfit = calculateOrganisationFiscalTaxableProfit(
    incomeData,
    deductionData
  );

  let incomeHouse = calculateHousingIncome(deductionData);
  //NOTE - Optellen alle vormen van inkomsten
  return fiscalTaxableProfit +
      incomeData.grossIncomeEmployment +
      incomeData.grossIncomeMiscellaneous +
      incomeHouse;
};

const totalDeductions = (deductionData, totalIncome) => {
  return (
    calculateDeductionMedicalExpenses(
      deductionData.medicalExpenses,
      deductionData.paidMedicalExpenses,
      deductionData.deductableMedicalExpensesBrackets,
      totalIncome
    ) +
    calculateDeductionOwnerOccupiedHousing(deductionData) +
    deductionData.paidPartnerAlimony +
    deductionData.paidAnnuity +
    deductionData.paidTravelExpenses
  );
};

//CONSTANT - (PERCENTAGE (TAXABLEINCOMEBOX1-CONSTANT))
const calculateGeneralTaxCredit = (
  employmentIncome,
  generalTaxCreditBrackets
) => {
  let taxCredit = 0;
  generalTaxCreditBrackets.forEach((bracket) => {
    if (employmentIncome >= bracket.min && employmentIncome <= bracket.max) {
      let incomeInBracket = employmentIncome;

      if (bracket.min !== 0) {
        incomeInBracket = employmentIncome - bracket.min;
      }

      if (bracket.percentage === 0) {
        taxCredit += bracket.constant;
      } else {
        taxCredit += bracket.constant - bracket.percentage * incomeInBracket;
      }
    }
  });

  return Math.round(taxCredit);
};

const calculateDeductionMedicalExpenses = (
  medicalExpenses,
  paidMedicalExpenses,
  deductableMedicalExpensesBrackets,
  totalIncome
) => {
  let deductableMedicalExpenses = 0;
  deductableMedicalExpenses += paidMedicalExpenses;

  if (medicalExpenses === true) {
    for (const bracket of deductableMedicalExpensesBrackets) {
      if (
        totalIncome >= bracket.min &&
        (!bracket.max || totalIncome < bracket.max)
      ) {
        if (bracket.constant) {
          deductableMedicalExpenses += bracket.constant;
        }
        if (bracket.percentage) {
          deductableMedicalExpenses += totalIncome * bracket.percentage;
        }
        break;
      }
    }
  }
  return deductableMedicalExpenses;
};

const calculateTaxableIncomeBox1 = (totalIncome, deductionData) => {
  console.log("DEDUCTION DATA", totalDeductions(deductionData, totalIncome))
  return Math.round(totalIncome - totalDeductions(deductionData, totalIncome));
};

const calculatelabourDiscount = (
  grossCompanyProfit,
  labourDiscountBrackets
) => {
  let labourDiscount = 0;
  for (const bracket of labourDiscountBrackets) {
    console.log("BRACKETS ARBEIDSKORTING", bracket)
    console.log("GROSSCOMPANY PROFIT", grossCompanyProfit)
    if (
      grossCompanyProfit > bracket.min &&
      (grossCompanyProfit <= bracket.max || bracket.max === Infinity)
    ) {

      let profitForBracket = 0
      //Tab!E23-Tab!D23*(C38-Tab!C22);0)

      if (bracket.max === Infinity) {
        profitForBracket = grossCompanyProfit - bracket.min;
      } else {
        profitForBracket =
          Math.min(grossCompanyProfit, bracket.max) - bracket.min;
      }

      if (bracket.percentage && bracket.percentage !== 0) {
        //NOTE - CONSTANT-PERCENTAGE * WINST IN BRACKET = ARBEIDSKORTING
        labourDiscount =
          bracket.constant - bracket.percentage * (profitForBracket);
      } else {
        labourDiscount = bracket.constant;
      }
      break;
    }
  }
  console.log("ARBEIDSKORTING", labourDiscount);
  return labourDiscount;
};

const calculateInvestmentDeduction = (
  grossExpectedInvestments,
  investmentDeductionBrackets
) => {
  let investmentDeduction = 0;

  for (const bracket of investmentDeductionBrackets) {
    if (
      grossExpectedInvestments >= bracket.min &&
      (!bracket.max || grossExpectedInvestments <= bracket.max)
    ) {
      if (bracket.percentage > 0) {
        investmentDeduction = grossExpectedInvestments * bracket.percentage;
      } else {
        investmentDeduction = bracket.constant;
      }
      break;
    }
  }

  return investmentDeduction;
};

const calculateIncomeTax = (taxableIncomeBox1, incomeTaxBrackets) => {
  let incomeTax = 0;
  incomeTaxBrackets.forEach((bracket) => {
    if (taxableIncomeBox1 > bracket.min) {
      let incomeInBracket = 0
      if (bracket.max === 0 || bracket.max === undefined) {
        incomeInBracket = taxableIncomeBox1 - bracket.min;
      } else {
        incomeInBracket =
            Math.min(taxableIncomeBox1, bracket.max) - bracket.min;
      }
      incomeTax += incomeInBracket * bracket.percentage;
    }
  });
  return Math.round(incomeTax);
};

const calculateIncomeRelatedCombinationTaxCredit = (
  grossProfit,
  incomeRelatedCombinationTaxBrackets,
) => {
  let taxCredit = 0;

  incomeRelatedCombinationTaxBrackets.forEach((bracket) => {
    if (grossProfit > bracket.min && (grossProfit <= bracket.max || bracket.max === undefined)) {
      let incomeInBracket = grossProfit - bracket.min;
      if (bracket.percentage && bracket.percentage !== 0) {
        taxCredit = incomeInBracket * bracket.percentage + bracket.constant;
      } else {
        taxCredit = bracket.constant;
      }
    }
  });
  return taxCredit;
};

const calculateHealthcareLaw = (taxableIncome, healthcareLawPercentage) => {
  return taxableIncome * healthcareLawPercentage;
};

const calculateSpendableIncomeAfterTax = (
  grossCompanyProfit,
  grossExpectedInvestments,
  incomeTaxAfterDiscounts,
  dividendTax,
  box3Tax,
  healthcareLaw
) => {
  let netIncome =
    grossCompanyProfit -
    (incomeTaxAfterDiscounts + healthcareLaw + grossExpectedInvestments);

  netIncome = netIncome - dividendTax;
  netIncome = netIncome - box3Tax;

  return netIncome;
};

const calculateSpendableMonthlyIncomeAfterTax = (spendableAfterTax) => {
  const months = 12;
  return spendableAfterTax / months;
};

const calculateDividendTax = (dividendPaid, dividendTaxPercentage) => {
  return dividendPaid * dividendTaxPercentage;
};

const calculateCapital = (capitalData, limitDeductableDebt) => {
  if (capitalData.capitalOverTaxFreeLimit) {
    return (
      capitalData.capitalSavings +
      capitalData.capitalInvestments -
      (capitalData.capitalDebts - limitDeductableDebt)
    );
  } else {
    return 0;
  }
};

const calculateCapitalTaxedInBox3 = (capitalData, capitalTaxData) => {
  if (capitalData.capitalOverTaxFreeLimit) {
    const taxedCapital =
      calculateCapital(capitalData, capitalTaxData.limitDeductableDebt) -
      capitalTaxData.taxFreeCapitalLimit;

    if (taxedCapital <= 0) {
      return 0;
    } else {
      return taxedCapital;
    }
  } else {
    return 0;
  }
};

const calculateCapitalReturnPercentage = (capitalData, capitalTaxData) => {
  if (capitalData.capitalOverTaxFreeLimit) {
    let capital = calculateCapital(
      capitalData,
      capitalTaxData.limitDeductableDebt
    );

    let taxableCapital = 0;
    let savingsReturns = 0;
    let investmentReturns = 0;
    let debtReturns = 0;
    let capitalReturnPct = 0;

    savingsReturns =
      capitalData.capitalSavings * capitalTaxData.fixedReturnOnSavings;
    investmentReturns =
      capitalData.capitalInvestments * capitalTaxData.fixedReturnOnInvestments;
    debtReturns =
      (capitalData.capitalDebts - capitalTaxData.limitDeductableDebt) *
      capitalTaxData.fixedReturnOnDebts;
    taxableCapital = savingsReturns + investmentReturns - debtReturns;
    capitalReturnPct = taxableCapital / capital;

    return capitalReturnPct;
  } else {
    return 0;
  }
};

const calculateBox3Income = (capitalData, capitalTaxData) => {
  if (capitalData.capitalOverTaxFreeLimit) {
    const income =
      calculateCapitalReturnPercentage(capitalData, capitalTaxData) *
      calculateCapitalTaxedInBox3(capitalData, capitalTaxData);
    if (income <= 0) {
      return 0;
    } else {
      return income;
    }
  } else {
    return 0;
  }
};

const calculateBox3Tax = (capitalData, capitalTaxData) => {
  if (capitalData.capitalOverTaxFreeLimit) {
    const tax =
      calculateBox3Income(capitalData, capitalTaxData) *
      capitalTaxData.capitalTaxPercentage;
    if (tax <= 0) {
      return 0;
    } else {
      return tax;
    }
  } else {
    return 0;
  }
};

export const taxCalculations = {
  calculateSmeProfitExemption,
  calculateHousingIncome,
  checkSelfEmploymentDeduction,
  calculateDeductionOwnerOccupiedHousing,
  calculateOrganisationFiscalTaxableProfit,
  calculateTotalIncome,
  totalDeductions,
  calculateGeneralTaxCredit,
  calculateDeductionMedicalExpenses,
  calculateTaxableIncomeBox1,
  calculatelabourDiscount,
  calculateInvestmentDeduction,
  calculateIncomeTax,
  calculateIncomeRelatedCombinationTaxCredit,
  calculateHealthcareLaw,
  calculateSpendableIncomeAfterTax,
  calculateSpendableMonthlyIncomeAfterTax,
  calculateDividendTax,
  calculateCapital,
  calculateCapitalTaxedInBox3,
  calculateCapitalReturnPercentage,
  calculateBox3Income,
  calculateBox3Tax,
};

export default taxCalculations;
