// InputComponent.js
import React from "react";

function InputComponent({ type, label, value, onChange }) {
  return (
    <div>
      <label>{label}</label>
      <input type={type} value={value} onChange={onChange} />
    </div>
  );
}

export default InputComponent;
