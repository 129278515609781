import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();
const apiUrl = process.env.REACT_APP_API_BASE_URL;

export function useAuth() {
  console.log("CONTEXT", AuthContext);
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      const response = await axios.get(apiUrl + "/auth/logout", {
        withCredentials: true,
      });
      if (response.status === 200) {
        localStorage.removeItem("user");
        setCurrentUser(null);
        navigate("/login");
      }
    } catch (error) {
      console.error("Uitloggen mislukt", error);
      alert("Uitloggen mislukt");
    }
  }, []);

  const login = async (email, password) => {
    try {
      let response = await axios.post(
        apiUrl + "/auth/login",
        { email, password },
        { withCredentials: true }
      );
      response = response.data.data
      if (response.expiresAt) {
        const user = {
          userId: response.userId,
          companyIds: response.companyIds,
          expiresAt: response.expiresAt,
        };

        localStorage.setItem("user", JSON.stringify(user));
        setCurrentUser(user);
        navigate("/calculation");
      } else {
        console.log(
          "Login was succesvol, maar de verloopdatum van de token is niet meegeleverd."
        );
      }
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const value = { currentUser, login, logout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
