import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./style/page-header.css";

const PageHeader = () => {
  const [className, setClassName] = useState(
    "page-header-container large-text"
  );
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/calculation") {
      setClassName("page-header-container large-text");
    } else {
      setClassName("page-header-container small-text");
    }
  }, [location]);

  return (
    <header className={className}>
      {location.pathname === "/calculation" && (
        <>
          <h1>
            {" "}
            De <span className="white">Be</span>
            <span className="red">-</span>
            <span className="white">easy</span>
          </h1>
          <h1>
            {" "}
            Be
            <span className="white">-</span>
            rekentool{" "}
          </h1>
        </>
      )}
      {location.pathname !== "/calculation" && (
        <h1>
          {" "}
          De <span className="white">Be</span>
          <span className="red">-</span>
          <span className="white">easy</span> Be
          <span className="white">-</span>
          rekentool{" "}
        </h1>
      )}
    </header>
  );
};

export default PageHeader;
