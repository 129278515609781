// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  display: flex;
  width: 100%;
  height: 184px;
  background-color: var(--Dark, #101a42);
  flex-direction: column;
}

.large {
  height: 320px;
}
`, "",{"version":3,"sources":["webpack://./src/components/header-component/style/header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,aAAa;EACb,sCAAsC;EACtC,sBAAsB;AACxB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".header-container {\n  display: flex;\n  width: 100%;\n  height: 184px;\n  background-color: var(--Dark, #101a42);\n  flex-direction: column;\n}\n\n.large {\n  height: 320px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
