import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DeductionsForm from "../deductions-form/deductions-form.component";
import axios from "axios";

function ConfigDetail() {
  const { year } = useParams();
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const url = process.env.REACT_APP_API_BASE_URL;

  const initialState = {
    year: new Date().getFullYear(),
    hoursCriterion: 0,
    starterDeduction: 0,
    starterDeductionYears: 0,
    selfEmploymentDeduction: 0,
    smeProfitExemption: 0,
    mortgageInterest: 0,
    notionalRentalValueMin: 0,
    notionalRentalValueMax: 0,
    incomeTaxBrackets: [],
    incomeRelatedCombinationTaxBrackets: [],
    investmentDeductionBrackets: [],
    labourDiscountBrackets: [],
    generalTaxCreditBrackets: [],
    deductableMedicalExpensesBrackets: [],
    healthcareLawPercentage: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          url + "/configuration/income-tax/" + year,
          {
            withCredentials: true,
          }
        );
        setConfig(response.data.data.config);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log(
            "Configuratie voor het opgegeven jaar niet gevonden, een lege configuratie wordt geladen."
          );
          setConfig(initialState);
        } else {
          if (error.response) {
            console.error("Server Error:", error.response.data);
            console.error("Status Code:", error.response.status);
            console.error("Headers:", error.response.headers);
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else if (!error.response) {
            console.log("Geen configuratie gevonden voor het jaar", year);
          } else {
            console.error("Request setup error:", error.message);
          }
        }
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [year]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Configuratie Inkomstenbelasting voor {year}</h1>
      <DeductionsForm incomeTaxConfig={config} setIncomeTaxConfig={setConfig} />
    </div>
  );
}

export default ConfigDetail;
