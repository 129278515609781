import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceIncomeStep({ handleChange, companyResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Loopje toevoegen voor meerdere auto's
    //TODO - Classnames aanpassen van income-tax naar wizard
      <div className="income-tax-content-container">
          <h2>Stap 3</h2>
          <div>
              <h1>Wat is uw inkomen?</h1>
              <div className="input-container">
                  <input
                      type="number"
                      value={(parseFloat(companyResult.incomeAfterTax/12)).toFixed(2)}
                      onChange={(e) =>
                          handleFieldUpdate("mainIncome", parseFloat(e.target.value).toFixed(2))
                      }
                  />
              </div>
          </div>
          <div>
              <h1>Wat is het inkomen van uw partner?</h1>
              <div className="input-container">
                  <input
                      type="number"
                      value={financeResult.partnerIncome}
                      onChange={(e) =>
                          handleFieldUpdate("partnerIncome", parseFloat(e.target.value).toFixed(2))
                      }
                  />
              </div>
          </div>
          <div>
              {companyResult.children && (
              <div>
                  <h1>Hoeveel ontvangt u aan kinderopvangtoeslag?</h1>
                  <div className="input-container">
                      <input
                          type="number"
                          value={financeResult.childCareAllowance}
                          onChange={(e) =>
                              handleFieldUpdate(
                                  "childCareAllowance",
                                  parseFloat(e.target.value)
                              )
                          }
                      />
                  </div>
              </div>
                )}
          </div>
      </div>
  );
}

export default FinanceIncomeStep;
