import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceMiscStep({ handleChange, companyResult, financeResult }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 9</h2>
      <div>
      {companyResult.children && (
        <div>
            <h1>Hoeveel betaalt u aan kinderopvang?</h1>
            <div className="input-container">
              <input
                type="number"
                value={financeResult.miscCosts.dayCareCosts}
                onChange={(e) =>
                  handleFieldUpdate(
                    "miscCosts.debtPaymentCosts",
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
        </div>
      )}
      </div>
      <div>
        <h1>Hoeveel betaalt u aan alimentatie?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.miscCosts.alimonyCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "miscCosts.alimonyCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan bijdrage voor studerende kinderen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.miscCosts.childStudySupportCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "miscCosts.childStudySupportCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan private afbetaling?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.miscCosts.debtPaymentCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "miscCosts.debtPaymentCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan private lease?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.miscCosts.privateLeaseCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "miscCosts.privateLeaseCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overige zaken?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.miscCosts.miscCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "miscCosts.miscCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceMiscStep;
