import React, { useState, useEffect } from "react";

import "../style/net-income-wizard.style.css";
import "./style/children-step.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the default CSS for the date picker
import { nl } from "date-fns/locale";

function ChildrenStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const [selectedDate, setSelectedDate] = useState(
    companyResult.youngestChildBirthDate
  );
  useEffect(() => {
    setSelectedDate(companyResult.youngestChildBirthDate);
  }, [companyResult.youngestChildBirthDate]);

  const handleDateChange = (date) => {
    handleChange("youngestChildBirthDate", date);
  };
  console.log("SELECTEDDATE", companyResult.youngestChildBirthDate);
  return (
    <div className="income-tax-content-container">
      <h2>Stap 2</h2>
      <h1>Heeft u kinderen?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.children === true ? "btn-yes selected-btn" : "btn-yes"
          }
          onClick={() => handleFieldUpdate("children", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.children === false ? "btn-no selected-btn" : "btn-no"
          }
          onClick={() => handleFieldUpdate("children", false)}
        >
          Nee
        </button>
      </div>
      {companyResult.children && (
        <div className="children-birthdate-picker">
          <h1 className="input-max-width">Geboortedatum van jongste kind</h1>
          <DatePicker
            selected={selectedDate ? new Date(selectedDate) : null}
            onChange={handleDateChange}
            className="date-picker"
            calendarClassName="date-picker-net-income-wizard"
            showPopperArrow={false}
            placeholderText="30-12-2001"
            locale={nl}
          />
        </div>
      )}
    </div>
  );
}

export default ChildrenStep;
