const calculateTotalCosts = (financeResult) => {
    let totalCosts = 0;

    const costCategories = [
        'energyCosts',
        'insuranceCosts',
        'subscriptionCosts',
        'educationCosts',
        'transportCosts',
        'miscCosts',
        'clothingCosts',
        'householdEffectsCosts',
        'unreimbursedMedicalCosts',
        'leisureCosts',
        'householdCosts',
    ];

    costCategories.forEach((category) => {
        if (Array.isArray(financeResult[category])) {
            financeResult[category].forEach((item) => {
                totalCosts += Object.values(item).reduce((acc, cost) => acc + cost, 0);
            });
        } else {
            totalCosts += Object.values(financeResult[category]).reduce(
                (acc, cost) => acc + cost,
                0
            );
        }
    });

    return totalCosts;
};

const calculateHouseholdIncome = (financeResult) => {
    let totalIncome = 0;

    const incomeFields = ['mainIncome', 'partnerIncome', 'childCareAllowance'];

    incomeFields.forEach((field) => {
        totalIncome += financeResult[field] || 0;
    });

    return totalIncome;
};

export const financeCalculations = {
    calculateTotalCosts,
    calculateHouseholdIncome,
};

export default financeCalculations;