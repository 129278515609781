import { ReactComponent as PlusIcon } from "../../../assets/svg/transparent-plus.svg";
import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

export default function FiscalPartnerStep({
  handleChange,
  companyResult,
  incomeTaxConfig,
}) {
  console.log("FiscalPartnerStep props", { handleChange, companyResult });
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 1</h2>
      <h1>Heeft u een fiscaal partner?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.fiscalPartner === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleFieldUpdate("fiscalPartner", true)}
        >
          Ja
        </button>
        <button
          className={
            companyResult.fiscalPartner === false
              ? "btn-no selected-btn"
              : "btn-no"
          }
          onClick={() => handleFieldUpdate("fiscalPartner", false)}
        >
          Nee
        </button>
      </div>
      <div className="income-tax-box-icon">
        <PlusIcon />
      </div>
    </div>
  );
}
