import { React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopNavigation from "../top-navigation/top-navigation.component";
import PageHeader from "../page-header/page-header.component";

import "./style/header.css";

const Header = ({ user }) => {
  const [headerClass, setHeaderClass] = useState("header-container");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/calculation") {
      setHeaderClass("header-container large");
    } else {
      setHeaderClass("header-container");
    }
  }, [location]);
  return (
    <div className={headerClass}>
      <TopNavigation user={user} />
      <PageHeader />
    </div>
  );
};

export default Header;
