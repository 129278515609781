// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-navigation-container {
  display: flex;
  color: var(--White, #fff);
  text-align: center;
  background-color: var(--Dark, #101a42);
  width: 100%;
  height: 80px;
  justify-content: center;
}

.top-navigation-items {
  display: inline-flex;
  padding: 27px 40px 24px 51px;
}

.top-navigation-items a {
  height: 32px;
  width: 201px;
  color: var(--White, #fff);
  cursor: pointer;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 3;
  flex-shrink: 0;
}

.top-navigation-auth {
  padding: 27px 40px 24px 51px;
  line-height: 3;
}

.auth-button {
  width: 96px;
  height: 28px;
  border-radius: 16px;
  background: var(--Green, #47b37c);
}

.auth-button:first-child {
  margin-right: 7px;
}

.login {
  background: transparent;
  border: 1px solid var(--Green, #47b37c);
}

.top-logo svg {
  width: 81px;
  height: 83px;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/top-navigation/style/top-navigation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,sCAAsC;EACtC,WAAW;EACX,YAAY;EACZ,uBAAuB;AACzB;;AAEA;EACE,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,uCAAuC;AACzC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;AAChB","sourcesContent":[".top-navigation-container {\n  display: flex;\n  color: var(--White, #fff);\n  text-align: center;\n  background-color: var(--Dark, #101a42);\n  width: 100%;\n  height: 80px;\n  justify-content: center;\n}\n\n.top-navigation-items {\n  display: inline-flex;\n  padding: 27px 40px 24px 51px;\n}\n\n.top-navigation-items a {\n  height: 32px;\n  width: 201px;\n  color: var(--White, #fff);\n  cursor: pointer;\n  text-align: center;\n  font-family: Montserrat;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: 3;\n  flex-shrink: 0;\n}\n\n.top-navigation-auth {\n  padding: 27px 40px 24px 51px;\n  line-height: 3;\n}\n\n.auth-button {\n  width: 96px;\n  height: 28px;\n  border-radius: 16px;\n  background: var(--Green, #47b37c);\n}\n\n.auth-button:first-child {\n  margin-right: 7px;\n}\n\n.login {\n  background: transparent;\n  border: 1px solid var(--Green, #47b37c);\n}\n\n.top-logo svg {\n  width: 81px;\n  height: 83px;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
