import "../style/net-income-wizard.style.css";
import "react-datepicker/dist/react-datepicker.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function CompanyRevenueStep({ handleChange, companyResult, incomeTaxConfig }) {
  const months = Object.keys(companyResult.differentiatedRevenue);
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const handleRevenueChange = (value) => {
    const monthlyRevenue = value;
    let yearlyRevenue = 0;
    if (companyResult.revenueDifferentiatesMonthly) {
      months.forEach((month) => {
        yearlyRevenue += month;
      });
    } else {
      yearlyRevenue = monthlyRevenue * 12;
    }
    handleFieldUpdate("grossAverageMonthlyRevenue", parseFloat(monthlyRevenue));
    handleFieldUpdate("grossExpectedRevenue", parseFloat(yearlyRevenue));
    console.log("yearly", yearlyRevenue);
    console.log("monthly", monthlyRevenue);
  };

  const handleCostChange = (value) => {
    const monthlyCosts = value;
    const yearlyCosts = monthlyCosts * 12;
    handleFieldUpdate("grossAverageMonthlyCosts", parseFloat(monthlyCosts));
    handleFieldUpdate("grossExpectedCosts", parseFloat(yearlyCosts));
    console.log("yearlycosts", yearlyCosts);
    console.log("monthlycosts", monthlyCosts);
  };

  const handleSetMonth = (month, value) => {
    handleFieldUpdate("differentiatedRevenue", {
      ...companyResult.differentiatedRevenue,
      [month]: value,
    });
  };
  const handleSetAllMonths = () => {
    const updatedDifferentiatedRevenue = {};
    months.forEach((month) => {
      updatedDifferentiatedRevenue[month] =
        parseFloat(companyResult.grossAverageMonthlyRevenue / 12).toFixed(2);
    });
    handleFieldUpdate("differentiatedRevenue", updatedDifferentiatedRevenue);
  };

  const handleYesButtonClick = (value) => {
    handleSetAllMonths();
    handleFieldUpdate("revenueDifferentiatesMonthly", value);
  };

  const handleNoButtonClick = (value) => {
    handleSetAllMonths();
    handleFieldUpdate("revenueDifferentiatesMonthly", value);
  };

  console.log(companyResult.grossAverageMonthlyRevenue);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 4</h2>
      <h1>Vul uw verwachte gemiddelde omzet per maand in</h1>
      <div className="input-container">
        <label>Bedrag</label>
        <input
          type="number"
          value={companyResult.grossAverageMonthlyRevenue}
          onChange={(e) => handleRevenueChange(e.target.value)}
        />
      </div>
      <h1>Vul uw verwachte gemiddelde kosten per maand in</h1>
      <div className="input-container">
        <label>Kosten</label>
        <input
          type="number"
          value={companyResult.grossAverageMonthlyCosts}
          onChange={(e) => handleCostChange(parseFloat(e.target.value))}
        />
      </div>
      <h1>
        Wijkt uw omzet <br /> maandelijks af?
      </h1>
      <div className="buttons">
        <button
          className={
            companyResult.revenueDifferentiatesMonthly === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleYesButtonClick(true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.revenueDifferentiatesMonthly === false
              ? "btn-no selected-btn"
              : "btn-no"
          }
          onClick={() => handleNoButtonClick(false)}
        >
          Nee
        </button>
        {companyResult.revenueDifferentiatesMonthly && (
          <div className="month-selection">
            <div className="month-column">
              {months.slice(0, 6).map((month) => (
                <div className="input-container" key={month}>
                  <label>{month.substring(0, 3)}</label>
                  <input
                    type="number"
                    placeholder={companyResult.differentiatedRevenue[month]}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      handleSetMonth(month, newValue);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className="month-column">
              {months.slice(6, 12).map((month) => (
                <div className="input-container" key={month}>
                  <label>{month.substring(0, 3)}</label>
                  <input
                    type="number"
                    placeholder={companyResult.differentiatedRevenue[month]}
                    onChange={(e) => {
                      const newValue = parseFloat(e.target.value);
                      handleSetMonth(month, newValue);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <h1>Heeft u inkomsten uit loondienst?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.employee === true ? "btn-yes selected-btn" : "btn-yes"
          }
          onClick={() => handleFieldUpdate("employee", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.employee === false ? "btn-no selected-btn" : "btn-no"
          }
          onClick={() => handleFieldUpdate("employee", false)}
        >
          Nee
        </button>
        {companyResult.employee && (
          <div>
            <h1>Hoeveel inkomsten uit loondienst heeft u jaarlijks?</h1>
            <div className="input-container">
              <label>Inkomsten</label>
              <input
                type="number"
                value={companyResult.grossIncomeEmployment}
                onChange={(e) =>
                  handleFieldUpdate(
                    "grossIncomeEmployment",
                    parseFloat(e.target.value)
                  )
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CompanyRevenueStep;
