import { useForm } from "../../hooks/useForm.hook";
import React from "react";
import { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import IntroStep from "./intro-step/intro-step.component";
import { useAuth } from "../../context/authContext";
import FinanceDescription from "./finance-description/income-tax-description.component";
import FinanceNavigation from "./finance-navigation/finance-navigation.component";
import FinanceHousingStep from "./finance-housing-step/finance-housing-step.component";
import FinanceCarStep from "./finance-car-step/finance-car-step.component";
import FinanceIncomeStep from "./finance-income-step/finance-income-step.component";
import FinanceEnergyStep from "./finance-energy-step/finance-energy-step.component";
import FinanceInsuranceStep from "./finance-insurance-step/finance-insurance-step.component";
import FinanceSubscriptionStep from "./finance-subscription-step/finance-subscription-step.component";
import FinanceEducationStep from "./finance-education-step/finance-education-step.component";
import FinanceTransportStep from "./finance-transport-step/finance-transport-step.component";
import FinanceMiscStep from "./finance-misc-step/finance-misc-step.component";
import FinanceClothingStep from "./finance-clothing-step/finance-clothing-step.component";
import FinanceHouseholdEffectsStep from "./finance-householdeffects-step/finance-householdeffects-step.component";
import FinanceUnreimbursedMedicalCostsStep from "./finance-unreimbursed-medical-step/finance-unreimbursed-medical-step.component";
import FinanceLeisureCostsStep from "./finance-leisure-step/finance-leisure-step.component";
import FinanceHouseholdCostsStep from "./finance-household-step/finance-household-step.component";
import FinanceResult from "./finance-result/finance-result.component";
import stepCalculations from "../../utils/stepCalculations";

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "updateField":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

function FinanceWizard() {
  const { financeId } = useParams();
  const { currentUser, setCurrentUser } = useAuth();
  const companyId = currentUser.companyIds[0];

  const introTitle = "Checklist benodigdheden";
  const introContent =
    "Klik tijdens het proces op de vraag om een korte toelichting van de onderwerpen te krijgen.";

  const stepComponentMapping = {
    FinanceHousingStep: FinanceHousingStep,
    FinanceCarStep: FinanceCarStep,
    FinanceIncomeStep: FinanceIncomeStep,
    FinanceEnergyStep: FinanceEnergyStep,
    FinanceInsuranceStep: FinanceInsuranceStep,
    FinanceSubscriptionStep: FinanceSubscriptionStep,
    FinanceEducationStep: FinanceEducationStep,
    FinanceTransportStep: FinanceTransportStep,
    FinanceMiscStep: FinanceMiscStep,
    FinanceClothingStep: FinanceClothingStep,
    FinanceHouseholdEffectsStep: FinanceHouseholdEffectsStep,
    FinanceUnreimbursedMedicalCostsStep: FinanceUnreimbursedMedicalCostsStep,
    FinanceLeisureCostsStep: FinanceLeisureCostsStep,
    FinanceHouseholdCostsStep: FinanceHouseholdCostsStep,
  };

  const initialFinanceFormState = {
    //NOTE - Wizard settings
    //TODO - Uit een database halen en aanpasbaar maken.
    currentFinanceStep: 0,
    showNextButton: true,
    showPrevButton: false,
    categories: {
      vaste_lasten: {
        title: "Vaste lasten",
        subTitle: "Kosten die je maandelijks aan vaste lasten kwijt bent",
        steps: {
          housingStep: {
            order: 1,
            title: "Woninggegevens",
            component: "FinanceHousingStep",
            helper_title: "Woninggegevens",
            helper_content: "Gegevens over uw woning",
          },
          carStep: {
            order: 2,
            title: "Auto",
            component: "FinanceCarStep",
            helper_title: "Auto",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      inkomsten: {
        title: "Inkomsten",
        subTitle: "De inkomsten die maandelijks binnenkomen",
        steps: {
          incomeStep: {
            order: 3,
            title: "Inkomsten",
            component: "FinanceIncomeStep",
            helper_title: "Inkomsten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      uitgaven: {
        title: "Uitgaven",
        subTitle: "Je maandelijkse uitgaven",
        steps: {
          energyStep: {
            order: 4,
            title: "Energiekosten",
            component: "FinanceEnergyStep",
            helper_title: "Energiekosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          insuranceStep: {
            order: 5,
            title: "Verzekeringen",
            component: "FinanceInsuranceStep",
            helper_title: "Verzekeringen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          subscriptionStep: {
            order: 6,
            title: "Abonnementen & Telecom",
            component: "FinanceSubscriptionStep",
            helper_title: "Abonnementen & Telecom",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          educationStep: {
            order: 7,
            title: "Onderwijs",
            component: "FinanceEducationStep",
            helper_title: "Onderwijs",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          transportStep: {
            order: 8,
            title: "Vervoer",
            component: "FinanceTransportStep",
            helper_title: "Vervoer",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          miscStep: {
            order: 9,
            title: "Overige vaste lasten",
            component: "FinanceMiscStep",
            helper_title: "Overige vaste lasten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          clothingStep: {
            order: 10,
            title: "Kleding & Schoenen",
            component: "FinanceClothingStep",
            helper_title: "Kleding & Schoenen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          householdEffectsStep: {
            order: 11,
            title: "Inboedel, Huis & Tuin",
            component: "FinanceHouseholdEffectsStep",
            helper_title: "Inboedel, Huis & Tuin",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          unreimbursedMedicalCostsStep: {
            order: 12,
            title: "Niet-vergoede Ziektekosten",
            component: "FinanceUnreimbursedMedicalCostsStep",
            helper_title: "Niet-vergoede Ziektekosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          leisureCostsStep: {
            order: 13,
            title: "Vrijetijdsuitgaven",
            component: "FinanceLeisureCostsStep",
            helper_title: "Vrijetijdsuitgaven",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          householdCostsStep: {
            order: 14,
            title: "Huishoudelijke uitgaven",
            component: "FinanceHouseholdCostsStep",
            helper_title: "Huishoudelijke uitgaven",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
    },
  };

  const initialFinanceResult = {
    userId: currentUser.userId,
    companyResultId: currentUser.companyIds[0],
    housingCosts: {
      housingType: "appartement",
      constructionYear: new Date().getFullYear(),
    },
    carCosts: [
      {
        carType: "compact",
        carCondition: "nieuw",
        mileage: 50000,
      },
    ],
    mainIncome: 0,
    partnerIncome: 2400,
    childCareAllowance: 500,
    energyCosts: {
      gasCosts: 10,
      electricityCosts: 20,
      waterCosts: 30,
      realEstateTax: 40,
      cleaningLevy: 50,
      sewageCharges: 60,
      waterBoardCharges: 70,
      localCosts: 80,
    },
    insuranceCosts: {
      healthInsuranceCosts: 90,
      liabilityInsuranceCosts: 100,
      contentAndBuildingInsuranceCosts: 110,
      lifeInsuranceCosts: 120,
      miscInsuranceCosts: 130,
    },
    subscriptionCosts: {
      internetAndTelevisionCosts: 140,
      mobilePhoneCosts: 150,
      contributionAndSubscriptionCosts: 160,
      miscSubscriptionCosts: 170,
    },
    educationCosts: {
      childStudySupportCosts: 260,
      adultStudyCosts: 270,
    },
    transportCosts: {
      debtPaymentCosts: 180,
      motorVehicleTaxCosts: 190,
      transportMaintenanceCosts: 200,
      transportInsuranceCosts: 210,
      transportFuelCosts: 220,
      transportPublicTransportCosts: 230,
    },
    miscCosts: {
      dayCareCosts: 240,
      alimonyCosts: 250,
      debtPaymentCosts: 270,
      privateLeaseCosts: 280,
      childStudySupportCosts: 285,
      miscCosts: 290,
    },
    clothingCosts: {
      clothingAndShoesCosts: 300,
      miscCosts: 310,
    },
    householdEffectsCosts: {
      householdCosts: 320,
      houseMaintenanceCosts: 330,
      ownersAssociationContributionCosts: 340,
    },
    unreimbursedMedicalCosts: {
      ownRiskCosts: 350,
      overTheCounterMedicineCosts: 360,
      contributionCosts: 370,
    },
    leisureCosts: {
      vacationCosts: 380,
      hobbyCosts: 390,
      miscLeisureCosts: 400,
    },
    householdCosts: {
      foodCosts: 410,
      cleaningProductsCosts: 420,
      personalHygieneCosts: 430,
      domesticServicesCosts: 440,
      petsCosts: 450,
      smokingCosts: 460,
      miscCosts: 470,
    },
  };

  const [financeResult, dispatch] = useReducer(
    formReducer,
    initialFinanceResult
  );

  const [loading, setLoading] = useState(true);
  const [companyResult, setcompanyResults] = useState(null);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [currentComponent] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;

  const fetchCompanyResultData = async () => {
    try {
      const response = await axios.get(
        url +
          "/companyresults/" +
          currentUser.companyIds[0] +
          "/" +
          new Date().getFullYear(),
        {
          withCredentials: true,
        }
      );
      const resultData = response.data.data.result;
      if (resultData.length === 0) {
        setcompanyResults(initialFinanceResult);
      } else {
        setcompanyResults(resultData);
        console.log("COMPANY RESPONSE", resultData);
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchFinanceData = async () => {
    try {
      const response = await axios.get(
        url + "/finance/" + currentUser.userId + "/" + financeId,
        {
          withCredentials: true,
        }
      );
      const resultData = response.data.data.result;
      if (resultData.length === 0) {
        dispatch({ type: "update", payload: initialFinanceResult });
      } else {
        dispatch({ type: "update", payload: resultData[0] });
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyResultData();
    fetchFinanceData();
  }, []);

  const handleFormChange = (field, value) => {
    dispatch({ type: "updateField", field, value });
  };

  const handleStepChange = (value) => {
    handleChange("currentFinanceStep", value);
  };

  const [financeFormState, handleChange] = useForm(initialFinanceFormState);

  const getcurrentFinanceStepComponent = () => {
    for (const [boxKey, boxValue] of Object.entries(
      financeFormState.categories
    )) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === financeFormState.currentFinanceStep) {
          return stepValue.component;
        }
      }
    }
    console.log(
      "No matching component found for current step:",
      financeFormState.currentFinanceStep
    );
    return null;
  };

  useEffect(() => {
  }, [currentComponent]);

  const handleSubmit = async () => {
    try {
      if (financeId) {
        const patchUrl = `${url}/finance/${currentUser.userId}/${financeId}`;
        const patchResponse = await axios.patch(
          patchUrl,
          {
            results: financeResult,
          },
          {
            withCredentials: true,
          }
        );
        dispatch({ type: "update", payload: patchResponse.data.data.result });
      } else {
        console.log("POST");
        const postUrl = `${url}/finance/${currentUser.userId}`;
        const postResponse = await axios.post(
          postUrl,
    {
            finance: financeResult,
          },{
              withCredentials: true,
          });
        dispatch({ type: "update", payload: postResponse.data.data.result });
      }
      setIsSaveClicked(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReset = () => {
    setIsSaveClicked(false);
  };

  const handleBtnSaveClick = () => {
    handleSubmit();
  };

  const getCurrentHelperContent = () => {
    for (const [boxKey, boxValue] of Object.entries(
      financeFormState.categories
    )) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === financeFormState.currentFinanceStep) {
          return {
            title: stepValue.helper_title,
            content: stepValue.helper_content,
          };
        }
      }
    }
    return { title: "", content: "" };
  };

  const prevStep = () => {
    const prevStep = financeFormState.currentFinanceStep - 1;
    handleStepChange(prevStep);
  };

  const nextStep = () => {
    const nextStep = financeFormState.currentFinanceStep + 1;
    handleStepChange(nextStep);
  };

  const totalFinanceSteps = stepCalculations.calculateTotalSteps(financeFormState.categories); // TODO - Calculate aan de hand van lijst

  const { title, content } = getCurrentHelperContent();

  if (loading) {
    return <p>Data inladen...</p>;
  }

  if (!financeResult) {
    return <p>Geen kasboek gevonden.</p>;
  }

  const DynamicStepComponent =
    stepComponentMapping[getcurrentFinanceStepComponent()];
  return (
    <div className="container-3-column income-tax-container">
      <div className="column-1-wide income-tax-wizard-navigation">
        <FinanceNavigation
          categories={financeFormState.categories}
          currentFinanceStep={financeFormState.currentFinanceStep}
          handleChange={handleStepChange}
        />
      </div>
      <div className="column-2-wide income-tax-wizard-content">
        {!isSaveClicked && financeFormState.currentFinanceStep === 0 && (
          <IntroStep />
        )}
        {!isSaveClicked &&
          financeFormState.currentFinanceStep > 0 &&
          DynamicStepComponent && (
            <DynamicStepComponent
              handleChange={handleFormChange}
              companyResult={companyResult}
              financeResult={financeResult}
            />
          )}
        {isSaveClicked && (
          <FinanceResult
            companyResult={companyResult}
            financeResult={financeResult}
          />
        )}
      </div>
      <div className="column-1-wide income-tax-wizard-description">
        {financeFormState.currentFinanceStep === 0 && (
          <FinanceDescription title={introTitle} content={introContent} />
        )}
        {financeFormState.currentFinanceStep > 0 && (
          <FinanceDescription title={title} content={content} />
        )}
      </div>
      <div className=".column-full-width income-tax-navigation">
        {!isSaveClicked && financeFormState.currentFinanceStep > 1 && (
          <button className="btn-previous" onClick={prevStep}>
            Vorige
          </button>
        )}
        {!isSaveClicked &&
          financeFormState.currentFinanceStep < totalFinanceSteps &&
          financeFormState.currentFinanceStep !== 0 && (
            <button className="btn-next" onClick={nextStep}>
              Volgende
            </button>
          )}
        {!isSaveClicked && financeFormState.currentFinanceStep === 0 && (
          <button className="btn-next" onClick={nextStep}>
            Start
          </button>
        )}
        {!isSaveClicked &&
          financeFormState.currentFinanceStep === totalFinanceSteps && (
            <button className="btn-save" onClick={handleBtnSaveClick}>
              Opslaan
            </button>
          )}
        <div>
          {isSaveClicked && (
            <button className="btn-reset" onClick={handleReset}>
              Terug naar formulier
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FinanceWizard;
