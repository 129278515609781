import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function DisabilityInsuranceStep({ handleChange, companyResult, incomeTaxConfig }) {
    const { handleFieldUpdate } = useFieldUpdater(handleChange);

    return (
        <div className="income-tax-content-container">
            <h2>Stap 5</h2>
            <h1>Heeft u een arbeidsongeschiktheidsverzekering (AOV)?</h1>
            <div className="buttons">
                <button
                    className={
                        companyResult.disabilityInsurance === true ? "btn-yes selected-btn" : "btn-yes"
                    }
                    onClick={() => handleFieldUpdate("disabilityInsurance", true)}
                >
                    Ja
                </button>

                <button
                    className={
                        companyResult.disabilityInsurance === false ? "btn-no selected-btn" : "btn-no"
                    }
                    onClick={() => handleFieldUpdate("disabilityInsurance", false)}
                >
                    Nee
                </button>
            </div>
            {companyResult.disabilityInsurance && (
                <div>
                    <h1>Hoeveel heeft u het afgelopen jaar betaald aan premie voor uw AOV?</h1>
                    <div className="input-container">
                        <label>Bedrag</label>
                        <input
                            type="number"
                            value={companyResult.disabilityInsurancePaid}
                            onChange={(e) =>
                                handleFieldUpdate("disabilityInsurancePaid", parseFloat(e.target.value))
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default DisabilityInsuranceStep;
