import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function CapitalStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  const calculeTaxFreeCapital = () => {
    let taxFreeCapital = incomeTaxConfig.taxFreeCapitalLimit;

    if (companyResult.fiscalPartner) {
      taxFreeCapital = taxFreeCapital * 2;
    }

    return taxFreeCapital;
  };

  return (
    <div className="income-tax-content-container">
      <h2>Stap 12</h2>
      <h1>
        Heeft u een eigen vermogen van meer dan &euro;
        {calculeTaxFreeCapital()}?
      </h1>
      <div className="buttons">
        <button
          className={
            companyResult.capitalOverTaxFreeLimit === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleFieldUpdate("capitalOverTaxFreeLimit", true)}
        >
          Ja
        </button>
        <button
          className={
            companyResult.capitalOverTaxFreeLimit === false
              ? "btn-no selected-btn"
              : "btn-no"
          }
          onClick={() => handleFieldUpdate("capitalOverTaxFreeLimit", false)}
        >
          Nee
        </button>
      </div>
      {companyResult.capitalOverTaxFreeLimit && (
        <div>
          <h1>Hoeveel spaargeld heeft u?</h1>
          <div className="input-container">
            <input
              type="number"
              value={companyResult.capitalSavings}
              onChange={(e) =>
                handleFieldUpdate("capitalSavings", parseFloat(e.target.value))
              }
            />
          </div>
          <h1>Hoeveel geld heeft u geïnvesteerd (denk aan beleggingen)?</h1>
          <div className="input-container">
            <label>Bedrag</label>
            <input
              type="number"
              value={companyResult.capitalInvestments}
              onChange={(e) =>
                handleFieldUpdate(
                  "capitalInvestments",
                  parseFloat(e.target.value)
                )
              }
            />
          </div>
          <h1>Hoeveel schulden heeft u (denk aan persoonlijke leningen)?</h1>
          <div className="input-container">
            <label>Bedrag</label>
            <input
              type="number"
              value={companyResult.capitalDebts}
              onChange={(e) =>
                handleFieldUpdate("capitalDebts", parseFloat(e.target.value))
              }
            />
          </div>
          {companyResult.fiscalPartner && (
            <div>
              <h1>Welk percentage wilt u bij uw fiscaal partner aangeven?</h1>
              <div className="input-container">
                <label>Bedrag</label>
                <input
                  type="number"
                  value={companyResult.capitalTaxFiscalPartnerPercentage}
                  onChange={(e) =>
                    handleFieldUpdate(
                      "capitalTaxFiscalPartnerPercentage",
                      parseFloat(e.target.value)
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CapitalStep;
