import { useCallback } from "react";

const useDeductionFieldUpdate = (handleFieldUpdate, companyResult) => {
  const handleDeductionFieldUpdate = useCallback(
    (field, value) => {
      handleFieldUpdate("deductions", {
        ...companyResult.deductions,
        [field]: value,
      });
    },
    [handleFieldUpdate, companyResult.deductions]
  );

  return { handleDeductionFieldUpdate };
};

export default useDeductionFieldUpdate;
