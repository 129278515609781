import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceHouseholdEffectsStep({
  handleChange,
  companyResult,
  financeResult,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 11</h2>
      <div>
        <h1>Hoeveel betaalt u aan inboedel?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdEffectsCosts.householdCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdEffectsCosts.householdCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan onderhoud huis en tuin?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.householdEffectsCosts.houseMaintenanceCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "householdEffectsCosts.houseMaintenanceCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>
          Hoeveel betaalt u aan onderhoud bijdrage vereniging van eigenaren?
        </h1>
        <div className="input-container">
          <input
            type="number"
            value={
              financeResult.householdEffectsCosts
                .ownersAssociationContributionCosts
            }
            onChange={(e) =>
              handleFieldUpdate(
                "householdEffectsCosts.ownersAssociationContributionCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceHouseholdEffectsStep;
