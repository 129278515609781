import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";
import useDeductionFieldUpdate from "../../../hooks/useDeductionFieldUpdate.hook";

function AnnuityStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);
  const { handleDeductionFieldUpdate } = useDeductionFieldUpdate(
    handleFieldUpdate,
    companyResult
  );

  return (
    <div className="income-tax-content-container">
      <h2>Stap 10</h2>
      <h1>Heeft u een lijfrenteproduct?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.deductions.annuity === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleDeductionFieldUpdate("annuity", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.deductions.annuity === false
              ? "btn-no selected-btn"
              : "btn-no"
          }
          onClick={() => handleDeductionFieldUpdate("annuity", false)}
        >
          Nee
        </button>
      </div>
      {companyResult.deductions.annuity && (
        <div>
          <h1>
            Hoeveel heeft u het afgelopen jaar in uw lijfrenteproduct
            geinvesteerd?
          </h1>
          <div className="input-container">
            <label>Inleg</label>
            <input
              type="number"
              value={companyResult.deductions.paidAnnuity}
              onChange={(e) =>
                handleDeductionFieldUpdate(
                  "paidAnnuity",
                  parseFloat(e.target.value)
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default AnnuityStep;
