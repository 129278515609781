import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";

function NetIncomeOverview() {
  const [loading, setLoading] = useState(true);
  const [companyResults, setcompanyResults] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const currentYear = new Date().getFullYear();
  const { currentUser } = useAuth();

  const fetchCompanyResultData = async () => {
    try {
      const response = await axios.get(
        url + "/companyresults/" + currentUser.companyIds[0],
        {
          withCredentials: true,
        }
      );
      console.log("RESPONSE", response);
      const resultsData = response.data.data.results;
      setcompanyResults(resultsData);
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyResultData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  console.log("RESULTS", companyResults);
  return (
    <div className="column-full-width center">
      <h1>Bedrijfsresultaten</h1>
      <table>
        <thead>
          <tr>
            <th>Jaar</th>
            <th>
              <button>
                <Link
                  to={`/calculation/${currentUser.companyIds[0]}/${currentYear}`}
                >
                  Nieuwe Calculatie maken
                </Link>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {companyResults &&
            companyResults.map((result) => (
              <tr key={result.year}>
                <td>
                  <Link to={`/calculation/${result.companyId}/${result.year}`}>
                    Bedrijfsresultaat voor {result.year}
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default NetIncomeOverview;
