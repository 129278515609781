// DeductionsForm.js
import React, { useState } from "react";
import BracketManager from "./bracket-manager/bracket-manager.component";
import axios from "axios";
import InputComponent from "../input-component/input-component.component";

function DeductionsForm({ incomeTaxConfig, setIncomeTaxConfig }) {
  const [localFormState, setLocalFormState] = useState(incomeTaxConfig);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  const updateBrackets = (bracketType, brackets) => {
    setLocalFormState((prevState) => ({
      ...prevState,
      [bracketType]: brackets,
    }));
  };

  const handleLocalChange = (field, value) => {
    setLocalFormState((prevState) => ({ ...prevState, [field]: value }));
  };

  const handleSubmit = async () => {
    try {
      const url = apiUrl + "/configuration/income-tax/" + incomeTaxConfig.year;
      const response = await axios.patch(url,
  {
            config: localFormState,
        }  ,
        {
            withCredentials: true,
        },
      );
      setIncomeTaxConfig(response.data.data.config);
      console.log("Response:", response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="form-section">
      <h2>Configuratie Urencriterium</h2>
      <InputComponent
        label="Urencriterium"
        type="number"
        value={localFormState.hoursCriterion}
        onChange={(e) => handleLocalChange("hoursCriterion", e.target.value)}
      />
      <h2>Configuratie Startersaftrek</h2>
      <InputComponent
        label="Startersaftrek"
        type="number"
        value={localFormState.starterDeduction}
        onChange={(e) => handleLocalChange("starterDeduction", e.target.value)}
      />
      <InputComponent
        label="Startersaftrek maximum in jaren"
        type="number"
        value={localFormState.starterDeductionYears}
        onChange={(e) =>
          handleLocalChange("starterDeductionYears", e.target.value)
        }
      />
      <h2>Configuratie Zelfstandigenaftrek</h2>
      <InputComponent
        label="Zelfstandigenaftrek in euro's"
        type="number"
        value={localFormState.selfEmploymentDeduction}
        onChange={(e) =>
          handleLocalChange("selfEmploymentDeduction", e.target.value)
        }
      />
      <h2>Configuratie MKB Winstvrijstelling</h2>
      <InputComponent
        label="MKB Winstvrijstelling in procenten"
        type="number"
        value={localFormState.smeProfitExemption}
        onChange={(e) =>
          handleLocalChange("smeProfitExemption", e.target.value)
        }
      />
      <h2>Configuratie Hypotheekrente</h2>
      <InputComponent
        label="Hypotheekrente in procenten"
        type="number"
        value={localFormState.mortgageInterest}
        onChange={(e) => handleLocalChange("mortgageInterest", e.target.value)}
      />
      <h2>Configuratie Eigenwoningforfaite (EWF)</h2>
      <InputComponent
        label="EWF Minimum in procenten"
        type="number"
        value={localFormState.notionalRentalValueMin}
        onChange={(e) =>
          handleLocalChange("notionalRentalValueMin", e.target.value)
        }
      />
      <InputComponent
        label="EWF Maximum in procenten"
        type="number"
        value={localFormState.notionalRentalValueMax}
        onChange={(e) =>
          handleLocalChange("notionalRentalValueMax", e.target.value)
        }
      />
      <h2>Configuratie belasting box 1</h2>
      <h3>Inkomstenbelasting Schijven</h3>
      <BracketManager
        brackets={localFormState.incomeTaxBrackets}
        updateBrackets={updateBrackets}
        bracketType="incomeTaxBrackets"
      />
      <h3>Inkomensafhankelijke combinatiekorting Schijven</h3>
      <BracketManager
        brackets={localFormState.incomeRelatedCombinationTaxBrackets}
        updateBrackets={updateBrackets}
        bracketType="incomeRelatedCombinationTaxBrackets"
      />
      <h3>Investeringsaftrek Schijven</h3>
      <BracketManager
        brackets={localFormState.investmentDeductionBrackets}
        updateBrackets={updateBrackets}
        bracketType="investmentDeductionBrackets"
      />
      <h3>Arbeidskortingaftrek Schijven</h3>
      <BracketManager
        brackets={localFormState.labourDiscountBrackets}
        updateBrackets={updateBrackets}
        bracketType="labourDiscountBrackets"
      />
      <h3>Algemene heffingskorting schijven</h3>
      <BracketManager
        brackets={localFormState.generalTaxCreditBrackets}
        updateBrackets={updateBrackets}
        bracketType="generalTaxCreditBrackets"
      />
      <h3>Aftrekbare ziektekosten schijven</h3>
      <BracketManager
        brackets={localFormState.deductableMedicalExpensesBrackets}
        updateBrackets={updateBrackets}
        bracketType="deductableMedicalExpensesBrackets"
      />
      <h2>Configuratie belasting box 2 - Dividendbelasting</h2>
      <InputComponent
        label="Dividendbelasting percentage voor eindgebruiker"
        type="number"
        value={localFormState.dividendTaxPercentage}
        onChange={(e) =>
          handleLocalChange("dividendTaxPercentage", e.target.value)
        }
      />
      <h2>Configuratie belasting box 3 - Vermogensbelasting</h2>
      <InputComponent
        label="Heffingsvrij vermogen"
        type="number"
        value={localFormState.taxFreeCapitalLimit}
        onChange={(e) =>
          handleLocalChange("taxFreeCapitalLimit", e.target.value)
        }
      />
      <InputComponent
        label="Drempel aftrekbare schulden"
        type="number"
        value={localFormState.limitDeductableDebt}
        onChange={(e) =>
          handleLocalChange("limitDeductableDebt", e.target.value)
        }
      />
      <InputComponent
        label="Forfaitair rendement spaargeld"
        type="number"
        value={localFormState.fixedReturnOnSavings}
        onChange={(e) =>
          handleLocalChange("fixedReturnOnSavings", e.target.value)
        }
      />
      <InputComponent
        label="Forfaitair rendement beleggingen"
        type="number"
        value={localFormState.fixedReturnOnInvestments}
        onChange={(e) =>
          handleLocalChange("fixedReturnOnInvestments", e.target.value)
        }
      />
      <InputComponent
        label="Forfaitair rendement aftrekbare schuld"
        type="number"
        value={localFormState.fixedReturnOnDebts}
        onChange={(e) =>
          handleLocalChange("fixedReturnOnDebts", e.target.value)
        }
      />
      <InputComponent
        label="Vermogensbelasting in procenten"
        type="number"
        value={localFormState.capitalTaxPercentage}
        onChange={(e) =>
          handleLocalChange("capitalTaxPercentage", e.target.value)
        }
      />
      <button type="button" onClick={handleSubmit}>
        Configuratie opslaan
      </button>
    </div>
  );
}

export default DeductionsForm;
