import { useForm } from "../../hooks/useForm.hook";
import React from "react";
import { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import IntroStep from "./intro-step/intro-step.component";
import IncomeTaxDescription from "./income-tax-description/income-tax-description.component";
import FiscalPartnerStep from "./fiscal-partner-step/fiscal-partner-step.component";
import ChildrenStep from "./children-step/children-step.component";
import LegalFormStep from "./legal-form-step/legal-form-step.component";
import CompanyRevenueStep from "./company-revenue-step/company-revenue-step.component";
import HousingStep from "./housing-step/housing-step.component";
import MedicalExpensesStep from "./medical-expenses-step/medical-expenses-step.component";
import GiftsStep from "./gifts-step/gifts-step.component";
import PartnerAlimonyStep from "./partner-alimony-step/partner-alimony-step.component";
import IncomeResult from "./income-result/income-result.component";
import IncomeTaxNavigation from "./income-tax-navigation/income-tax-navigation.component";
import TravelExpensesStep from "./travel-expenses-step/travel-expenses-step.component";
import AnnuityStep from "./annuity-step/annuity-step.component";
import DividendStep from "./dividend-step/dividend-step.component";
import CapitalStep from "./capital-step/capital-step.component";
import DisabilityInsuranceStep from "./disability-insurance-step/disability-insurance-step.component";

const formReducer = (state, action) => {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };
    case "updateField":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

function NetIncomeWizard() {
  const { companyId, year } = useParams();

  const introTitle = "Checklist benodigdheden";
  const introContent =
    "Klik tijdens het proces op de vraag om een korte toelichting van de onderwerpen te krijgen.";

  const stepComponentMapping = {
    FiscalPartnerStep: FiscalPartnerStep,
    ChildrenStep: ChildrenStep,
    LegalFormStep: LegalFormStep,
    CompanyRevenueStep: CompanyRevenueStep,
    DisabilityInsuranceStep: DisabilityInsuranceStep,
    HousingStep: HousingStep,
    MedicalExpensesStep: MedicalExpensesStep,
    GiftsStep: GiftsStep,
    PartnerAlimonyStep: PartnerAlimonyStep,
    TravelExpensesStep: TravelExpensesStep,
    AnnuityStep: AnnuityStep,
    DividendStep: DividendStep,
    CapitalStep: CapitalStep,
  };

  const initialFormState = {
    //NOTE - Wizard settings
    //TODO - Uit een database halen en aanpasbaar maken.
    currentStep: 0,
    showNextButton: true,
    showPrevButton: false,
    categories: {
      box_1: {
        title: "Box 1",
        subTitle: "Inkomen uit werk en woning",
        steps: {
          fiscalPartner: {
            order: 1,
            title: "Fiscaal partner",
            component: "FiscalPartnerStep",
            helper_title: "Fiscaal partner uitnodigen",
            helper_content:
              "Gebruik de button om je fiscale partner uit te nodigen.",
          },
          children: {
            order: 2,
            title: "Kinderen",
            component: "ChildrenStep",
            helper_title: "Kinderen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          legalForm: {
            order: 3,
            title: "Eenmanszaak",
            component: "LegalFormStep",
            helper_title: "Rechtsvorm",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          companyRevenue: {
            order: 4,
            title: "Omzet/kosten",
            component: "CompanyRevenueStep",
            helper_title: "Omzet en kosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          housing: {
            order: 5,
            title: "Woning",
            component: "HousingStep",
            helper_title: "Woning",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          medicalExpenses: {
            order: 6,
            title: "Ziektekosten",
            component: "MedicalExpensesStep",
            helper_title: "Aftrekbare ziektekosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          gifts: {
            order: 7,
            title: "Giften",
            component: "GiftsStep",
            helper_title: "Geschonken giften",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          partnerAlimony: {
            order: 8,
            title: "Partneralimentatie",
            component: "PartnerAlimonyStep",
            helper_title: "Betaalde alimentatie",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          travelExpenses: {
            order: 9,
            title: "Reiskosten",
            component: "TravelExpensesStep",
            helper_title: "Aftrekbare reiskosten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
          annuity: {
            order: 10,
            title: "Lijfrente",
            component: "AnnuityStep",
            helper_title: "Aftrekbare lijfrente producten",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      box_2: {
        title: "Box 2",
        subTitle: "Dividend",
        steps: {
          dividend: {
            order: 11,
            title: "Uitgekeerd dividend",
            component: "DividendStep",
            helper_title: "Dividend uitkeren",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
      box_3: {
        title: "Box 3",
        subTitle: "Kapitaal",
        steps: {
          capital: {
            order: 12,
            title: "Vermogen",
            component: "CapitalStep",
            helper_title: "Belast vermogen",
            helper_content: "Een stukje uitleg geschreven door RY",
          },
        },
      },
    },
  };
  //NOTE - Company result settings
  const initialCompanyResult = {
    differentiatedRevenue: {
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    deductions: {
      mortgageInterestDeduction: false,
      paidMortgageInterest: 0,
      mortgageCosts: false,
      paidMortgageCosts: 0,
      notionalRentalValue: false,
      medicalExpenses: false,
      paidMedicalExpenses: 0,
      gifts: false,
      paidGifts: 0,
      partnerAlimony: false,
      paidPartnerAlimony: 0,
      travelExpenses: false,
      paidTravelExpenses: 0,
      annuity: false,
      paidAnnuity: 0,
    },
    companyYear: new Date().getFullYear(),
    starterDeductionEligible: false,
    year: year,
    dividend: false,
    dividendPaid: 0,
    grossExpectedRevenue: 0,
    revenueDifferentiatesMonthly: false,
    grossAverageMonthlyRevenue: 0,
    grossExpectedCosts: 0,
    grossAverageMonthlyCosts: 0,
    grossExpectedInvestments: 0,
    grossIncomeMiscellaneous: 0,
    wozValue: 0,
    hoursWorkingAsEntrepeneur: 1225,
    hoursWorkingAsEntrepeneurPercentage: 0,
    fiscalPartner: false,
    children: false,
    youngestChildBirthDate: false,
    isEntrepeneur: false,
    mainUserIsEntrepeneur: false,
    fiscalPartnerIsEntrepeneur: false,
    homeOwner: false,
    employee: false,
    ownerOccupiedHome: false,
    companyId: companyId,
    grossIncomeEmployment: 0,
    incomeAfterTax: 0,
    capitalOverTaxFreeLimit: false,
  };

  const [incomeTaxConfig, setIncomeTaxConfig] = useState(null);
  const [company, setCompany] = useState({ legalForm: "" });
  const [companyResult, dispatch] = useReducer(
    formReducer,
    initialCompanyResult
  );
  const [loading, setLoading] = useState(true);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;

  //NOTE Get Company
  const fetchCompanyData = async () => {
    try {
      const response = await axios.get(url + "/companies/" + companyId, {
        withCredentials: true,
      });
      setCompany(response.data.data.company);
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  //NOTE Get Configuration
  const fetchConfigData = async () => {
    try {
      const response = await axios.get(
        url + "/configuration/income-tax/" + year,
        {
          withCredentials: true, // Send cookies
        }
      );
      setIncomeTaxConfig(response.data);
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfigData();
  }, []);

  //NOTE Get companyResult 65818857ce9bbb04c880294f
  const fetchCompanyResultData = async () => {
    try {
      const response = await axios.get(
        url + "/companyresults/" + companyId + "/" + year,
        {
          withCredentials: true,
        }
      );
      const resultData = response.data.data.result;
      if (resultData.length === 0) {
        dispatch({ type: "update", payload: initialCompanyResult });
      } else {
        dispatch({ type: "update", payload: resultData });
      }
    } catch (error) {
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompanyResultData();
  }, []);

  const [formState, handleChange] = useForm(initialFormState);

  const getCurrentStepComponent = () => {
    for (const [boxKey, boxValue] of Object.entries(formState.categories)) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === formState.currentStep) {
          return stepValue.component;
        }
      }
    }
    console.log(
      "No matching component found for current step:",
      formState.currentStep
    );
    return null;
  };

  const handleStepChange = (value) => {
    handleChange("currentStep", value);
  };

  const handleFormChange = (field, value) => {
    dispatch({ type: "updateField", field, value });
  };

  useEffect(() => {
    const currentComponent = getCurrentStepComponent();
    setCurrentComponent(currentComponent);
  }, [formState.currentStep]);

  const handleSubmit = async () => {
    try {
      const checkUrl = `${url}/companyresults/${companyId}/${year}`;
      const checkResponse = await axios.get(checkUrl, {
        withCredentials: true,
      });

      if (checkResponse.status === 200) {
        const patchUrl = `${url}/companyresults/${companyId}/${year}`;
        const patchResponse = await axios.patch(
          patchUrl,
          {
            results: companyResult,
          },
          {
            withCredentials: true,
          }
        );
        dispatch({ type: "update", payload: patchResponse.data.data.result });
        setIsSaveClicked(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const postUrl = `${url}/companyresults/${companyId}/${year}`;
        const postResponse = await axios.post(
          postUrl,
          {
            results: companyResult,
          },
   {
            withCredentials: true,
          }
        );
        dispatch({ type: "update", payload: postResponse.data.data.result });
        setIsSaveClicked(true);
        console.log("ISSAVEDCLICK?", isSaveClicked);
      } else {
        console.log("ERROR", error);
        console.error("Error:", error);
      }
    }
  };

  const handleReset = () => {
    setIsSaveClicked(false);
  };

  const handleBtnSaveClick = () => {
    handleSubmit();
  };

  const getCurrentHelperContent = () => {
    for (const [boxKey, boxValue] of Object.entries(formState.categories)) {
      for (const [stepKey, stepValue] of Object.entries(boxValue.steps)) {
        if (stepValue.order === formState.currentStep) {
          return {
            title: stepValue.helper_title,
            content: stepValue.helper_content,
          };
        }
      }
    }
    return { title: "", content: "" };
  };

  const prevStep = () => {
    const prevStep = formState.currentStep - 1;
    handleStepChange(prevStep);
  };

  const nextStep = () => {
    const nextStep = formState.currentStep + 1;
    handleStepChange(nextStep);
  };
  const totalSteps = 12; // TODO - Calculate aan de hand van lijst

  const { title, content } = getCurrentHelperContent();

  if (loading) {
    return <p>Data inladen...</p>;
  }

  if (!incomeTaxConfig) {
    return <p>Geen inkomstenbelasting configuratie gevonden.</p>;
  }

  if (!companyResult) {
    return <p>Geen bedrijfsresultaten gevonden.</p>;
  }
  const DynamicStepComponent = stepComponentMapping[getCurrentStepComponent()];
  console.log("CR", companyResult);
  return (
    <div className="container-3-column income-tax-container">
      <div className="column-1-wide income-tax-wizard-navigation">
        <IncomeTaxNavigation
          categories={formState.categories}
          currentStep={formState.currentStep}
          handleChange={handleStepChange}
        />
      </div>
      <div className="column-2-wide income-tax-wizard-content">
        {console.log(
          "Rendering currentComponent:",
          currentComponent,
          "at step",
          formState.currentStep
        )}
        {!isSaveClicked && formState.currentStep === 0 && <IntroStep />}
        {!isSaveClicked &&
          formState.currentStep > 0 &&
          DynamicStepComponent && (
            <DynamicStepComponent
              handleChange={handleFormChange}
              companyResult={companyResult}
              incomeTaxConfig={incomeTaxConfig.data.config}
            />
          )}
        {isSaveClicked && (
          <IncomeResult
            companyResult={companyResult}
            incomeTaxConfig={incomeTaxConfig.data.config}
          />
        )}
      </div>
      <div className="column-1-wide income-tax-wizard-description">
        {formState.currentStep === 0 && (
          <IncomeTaxDescription title={introTitle} content={introContent} />
        )}
        {formState.currentStep > 0 && (
          <IncomeTaxDescription title={title} content={content} />
        )}
      </div>
      <div className=".column-full-width income-tax-navigation">
        {!isSaveClicked && formState.currentStep > 1 && (
          <button className="btn-previous" onClick={prevStep}>
            Vorige
          </button>
        )}
        {!isSaveClicked &&
          formState.currentStep < totalSteps &&
          formState.currentStep !== 0 && (
            <button className="btn-next" onClick={nextStep}>
              Volgende
            </button>
          )}
        {!isSaveClicked && formState.currentStep === 0 && (
          <button className="btn-next" onClick={nextStep}>
            Start
          </button>
        )}
        {!isSaveClicked && formState.currentStep === totalSteps && (
          <button className="btn-save" onClick={handleBtnSaveClick}>
            Opslaan
          </button>
        )}
        <div>
          {isSaveClicked && (
            <button className="btn-reset" onClick={handleReset}>
              Terug naar formulier
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NetIncomeWizard;
