import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/authContext";

function FinanceOverview() {
  const [loading, setLoading] = useState(true);
  const [financeResults, setFinanceResults] = useState(null);
  const url = process.env.REACT_APP_API_BASE_URL;
  const { currentUser } = useAuth();

  const fetchFinanceData = async () => {
    try {
      const response = await axios.get(url + "/finance/" + currentUser.userId, {
        withCredentials: true,
      });
      console.log("RESPONSE", response);
      const resultsData = response.data.data.results;
      setFinanceResults(resultsData);
    } catch (error) {
      // TODO - Extraheren van deze functionaliteit
      if (error.response) {
        console.error("Server Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinanceData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="column-full-width center">
      <h1>Kasboeken</h1>
      <table>
        <thead>
          <tr>
            <th>Naam</th>
            <th>
              <button>
                <Link to={`/finance/${currentUser.userId}`}>
                  Nieuw kasboek maken
                </Link>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {financeResults &&
            financeResults.map((result) => (
              <tr key={result._id}>
                <td>
                  <Link to={`/finance/${currentUser.userId}/${result._id}`}>
                    Kasboek {result._id}
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default FinanceOverview;
