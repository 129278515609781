import "../style/net-income-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

//TODO - Change to housingStep
function ChildrenStep({ handleChange, companyResult, incomeTaxConfig }) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    <div className="income-tax-content-container">
      <h2>Stap 5</h2>
      <h1>Heeft u of uw financieel partner een eigen woning?</h1>
      <div className="buttons">
        <button
          className={
            companyResult.homeOwner === true
              ? "btn-yes selected-btn"
              : "btn-yes"
          }
          onClick={() => handleFieldUpdate("homeOwner", true)}
        >
          Ja
        </button>

        <button
          className={
            companyResult.homeOwner === false ? "btn-no selected-btn" : "btn-no"
          }
          onClick={() => handleFieldUpdate("homeOwner", false)}
        >
          Nee
        </button>
      </div>
      {companyResult.homeOwner && (
        <div>
          <h1>Gaat het hier om een koopwoning?</h1>
          <div className="buttons">
            <button
              className={
                companyResult.ownerOccupiedHome === true
                  ? "btn-yes selected-btn"
                  : "btn-yes"
              }
              onClick={() => handleFieldUpdate("ownerOccupiedHome", true)}
            >
              Ja
            </button>

            <button
              className={
                companyResult.ownerOccupiedHome === false
                  ? "btn-no selected-btn"
                  : "btn-no"
              }
              onClick={() => handleFieldUpdate("ownerOccupiedHome", false)}
            >
              Nee
            </button>
          </div>
        </div>
      )}
      {companyResult.homeOwner && companyResult.ownerOccupiedHome && (
        <div>
          <h1>Wat was de WOZ-waarde van uw woning per 1 januari 2022?</h1>
          <div className="input-container">
            <label>WOZ</label>
            <input
              type="number"
              value={companyResult.wozValue}
              onChange={(e) =>
                handleFieldUpdate("wozValue", parseFloat(e.target.value))
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChildrenStep;
