// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.page-header-container h1 {
  margin: 0;
}

.large-text {
  height: 128px;
  margin: 40px 0 0 0;
  flex-direction: column;
}

.small-text {
  height: 28px;
  margin: 8px 0 0 0;
  flex-direction: row;
}

.small-text h1 {
  color: var(--Green, #47b37c);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/page-header/style/page-header.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".page-header-container {\n  display: flex;\n  width: 100%;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.page-header-container h1 {\n  margin: 0;\n}\n\n.large-text {\n  height: 128px;\n  margin: 40px 0 0 0;\n  flex-direction: column;\n}\n\n.small-text {\n  height: 28px;\n  margin: 8px 0 0 0;\n  flex-direction: row;\n}\n\n.small-text h1 {\n  color: var(--Green, #47b37c);\n  text-align: center;\n  font-family: Montserrat;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 800;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
