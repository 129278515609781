import "../style/finance-wizard.style.css";
import useFieldUpdater from "../../../hooks/useFieldUpdater.hook";

function FinanceSubscriptionStep({
  handleChange,
  companyResult,
  financeResult,
}) {
  const { handleFieldUpdate } = useFieldUpdater(handleChange);

  return (
    //TODO - Classnames aanpassen van income-tax naar finance
    <div className="income-tax-content-container">
      <h2>Stap 6</h2>
      <div>
        <h1>Hoeveel betaalt u voor televisie en internet?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.subscriptionCosts.internetAndTelevisionCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "subscriptionCosts.internetAndTelevisionCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u voor uw mobiele telefoon?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.subscriptionCosts.mobilePhoneCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "subscriptionCosts.mobilePhoneCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan contributies en abonnementen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={
              financeResult.subscriptionCosts.contributionAndSubscriptionCosts
            }
            onChange={(e) =>
              handleFieldUpdate(
                "subscriptionCosts.contributionAndSubscriptionCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
      <div>
        <h1>Hoeveel betaalt u aan overige abonnementen?</h1>
        <div className="input-container">
          <input
            type="number"
            value={financeResult.subscriptionCosts.miscSubscriptionCosts}
            onChange={(e) =>
              handleFieldUpdate(
                "subscriptionCosts.miscSubscriptionCosts",
                parseFloat(e.target.value)
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default FinanceSubscriptionStep;
