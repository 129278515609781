import React from "react";

import "../style/finance-wizard.style.css";
import "./style/finance-result.style.css";
import financeCalculations from "../../../utils/financeCalculations";

function FinanceResult({ companyResult, financeResult }) {
  const incomeData = {
    wozValue: companyResult.wozValue,
    mortgageCosts: companyResult.deductions.mortgageCosts,
  };

  const totalCosts = financeCalculations.calculateTotalCosts(financeResult);
  const totalIncome = financeCalculations.calculateHouseholdIncome(financeResult);
  const incomeOver = totalIncome - totalCosts;
  const totalSum = totalIncome + totalCosts + incomeOver;

  console.log('Total Costs:', totalCosts);
  console.log('Total Income:', totalIncome);

  const incomeProportion = (Math.abs(totalIncome) / totalSum) * 100;
  const costsProportion =
      (Math.abs(totalCosts) / totalSum) * 100;
  const overProportion =
      (Math.abs(incomeOver) / totalSum) * 100;

  return (
    <div className="income-tax-content-container">
      <h1 className="center">Alles is correct ingevuld!</h1>
      <div className="income-tax-overview-container">
        <div class="overview-text">
          <p>
            U heeft de complete wizard doorlopen en hier onder zie je wat er
            be-rekend is!
          </p>
          <p>
            Mocht u meer detail informatie willen het desbetreffende onderwerp
            uitklappen.
          </p>
          <p>
            En nog een verwijzing naar het dashboard waar ze hun financien bij
            kunnen houden.
          </p>
        </div>
        <div class="overview-results">
          <div class="result-item">
            <span class="indicator green-indicator"></span>
            <span class="amount">
              {parseFloat(totalIncome-totalCosts).toFixed(2)}
            </span>
            <span class="description">Maandelijks over na kosten</span>
          </div>
          <div class="result-item">
            <span class="indicator red-indicator"></span>
            <span class="amount">
              {parseFloat(totalCosts).toFixed(2)}
            </span>
            <span class="description">Maandelijkse kosten</span>
          </div>
          <div class="result-item">
            <span class="indicator yellow-indicator"></span>
            <span class="amount">
              {parseFloat(totalIncome).toFixed(2)}
            </span>
            <span class="description">Maandelijks inkomen</span>
          </div>
        </div>
      </div>
      <div className="income-distribution-bar">
        <div className="bar">
          <div
            className="segment green-segment"
            style={{ width: `${overProportion}%` }}
          >
            <span className="label">
              {overProportion.toFixed(1)}%
            </span>
          </div>
          <div
            className="segment red-segment"
            style={{ width: `${costsProportion}%` }}
          >
            <span className="label">{costsProportion.toFixed(1)}%</span>
          </div>
          <div
            className="segment yellow-segment"
            style={{ width: `${incomeProportion}%` }}
          >
            <span className="label">{incomeProportion.toFixed(1)}%</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinanceResult;
