import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/header-component/header.component";
import ConfigOverview from "./components/config-overview/config-overview.component";
import ConfigDetail from "./components/config-detail/config-detail.component";
import NetIncomeOverview from "./components/net-income-overview/net-income-overview.component";
import NetIncomeWizard from "./components/net-income-wizard/net-income-wizard.component";
import ProtectedRoute from "./components/protected-route/protected-route.component";
import Login from "./components/login-form/login-form.component";
import FinanceOverview from "./components/finance-overview/finance-overview.component";
import FinanceWizard from "./components/finance-wizard/finance-wizard.component";

import { useAuth } from "./context/authContext";

import "./App.css";

const App = () => {
  const { currentUser, setCurrentUser } = useAuth();

  useEffect(() => {
    const checkAuthStatus = async () => {
      const response = await fetch("/auth-check", { credentials: "include" });

      const data = await response.json();
      if (data.loggedIn) {
        setCurrentUser({
          //TODO - Fix dit met data
          /* Gebruikersinformatie */
        });
      } else {
        setCurrentUser(null);
        window.location.href = "/login";
      }
    };

    checkAuthStatus();
  }, [setCurrentUser]);

  return (
    <div className="app-container">
      <Header user={currentUser} />
      <div className="content-container">
        <Routes>
          <Route path="/login" element={<Login user={currentUser} />} />
          <Route
            path="/calculation"
            element={
              <ProtectedRoute>
                <NetIncomeOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculation/:companyId/:year"
            element={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pricing"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/approach"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/about"
            component={
              <ProtectedRoute>
                <NetIncomeWizard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/configurations"
            element={
              <ProtectedRoute>
                <ConfigOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/config/:year"
            element={
              <ProtectedRoute>
                <ConfigDetail />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <ProtectedRoute>
                <FinanceOverview />
              </ProtectedRoute>
            }
          />
            <Route
                path="/finance/:userId"
                element={
                    <ProtectedRoute>
                        <FinanceWizard />
                    </ProtectedRoute>
                }
            />
          <Route
            path="/finance/:userId/:financeId"
            element={
              <ProtectedRoute>
                <FinanceWizard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default App;
